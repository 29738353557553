import React from 'react';
import classNames from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: 1248,
    margin: '0 auto',
    padding: '0 16px',
    boxSizing: 'border-box',

    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },
}));

interface Props {
  className?: string;
}

export const Container: React.FC<Props> = ({ children, className }) => {
  const classes = useStyles();

  return <div className={classNames(classes.root, className)}>{children}</div>;
};
