import { format, isValid } from 'date-fns';
import sha1 from 'sha1';
import base64js from 'base64-js'

import { ApiErrorConstruction } from 'types/api';

export const maybeCallFn = (fn: any, ...args: any) => {
  return fn && typeof fn === 'function' && fn.apply(fn, args);
};

export function isExists(value) {
  return value !== undefined && value !== null;
}

export function safeArrayCheck(supposedArray) {
  return isExists(supposedArray) && Array.isArray(supposedArray);
}

export function toSearchParams(params) {
  const formatArray = (key, array) => {
    return array
      .map(value => {
        return key + encodeURIComponent('[]') + '=' + encodeURIComponent(value);
      })
      .join('&');
  };

  const formatObject = (key, object) => {
    return Object.keys(object)
      .map(innerKey => {
        const superInnerKey = `${key}${encodeURIComponent('[' + innerKey + ']')}`;

        if (Array.isArray(object[innerKey])) {
          return formatArray(superInnerKey, object[innerKey]);
        } else if (typeof object[innerKey] === 'object') {
          return formatObject(superInnerKey, object[innerKey]);
        } else {
          return formatString(superInnerKey, object[innerKey]);
        }
      })
      .join('&');
  };

  const formatString = (key, value) => {
    return key + '=' + encodeURIComponent(value);
  };

  return Object.keys(params)
    .map(key => {
      if (Array.isArray(params[key])) {
        return formatArray(encodeURIComponent(key), params[key]);
      } else if (typeof params[key] === 'object') {
        return formatObject(encodeURIComponent(key), params[key]);
      } else {
        return formatString(encodeURIComponent(key), params[key]);
      }
    })
    .join('&');
}

export function decodeSearchParams<T>(searchString): T {
  const searchParams = new URLSearchParams(searchString);
  const result = {};

  // @ts-ignore
  for (const [key, value] of searchParams.entries()) {
    result[key] = value;
  }

  return result as T;
}

interface CleanObjectParams {
  removeEmptyArray?: boolean;
  removeEmptyString?: boolean;
}

export function cleanObject<T>(obj: object, params: CleanObjectParams = {}): T {
  const cleanedObject = {};
  const { removeEmptyArray, removeEmptyString } = params;

  Object.keys(obj).forEach(key => {
    if (removeEmptyArray === true) {
      if (safeArrayCheck(obj[key]) && obj[key].length === 0) {
        return;
      }
    }

    if (removeEmptyString === true) {
      if (obj[key] === '') {
        return;
      }
    }

    if (!isExists(obj[key])) {
      return;
    }

    cleanedObject[key] = obj[key];
  });

  return cleanedObject as T;
}

export function parseApiSum(apiSum: string): number {
  return parseFloat(apiSum.replace(/,/, '.'));
}

export function parseInputCardNumber(cardNumber: string): string {
  return cardNumber.replace(/\s+/gi, '');
}

export function parseInputCardExpiry(cardExpiry: string) {
  const splitted = cardExpiry.split('/');

  return {
    month: splitted[0],
    year: splitted[1],
  };
}

export const BIRTHDATE_REGEXP = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))/;

export function errorHandle(
  error: ApiErrorConstruction | any,
  defaultError = 'Внутренняя ошибка'
): ApiErrorConstruction {
  if (error && error.error) {
    return error;
  } else {
    return {
      error: defaultError,
      isHandled: false,
    };
  }
}

export function saveAs(blob: Blob, fileName: string) {
  var url = window.URL.createObjectURL(blob);

  var anchorElem = document.createElement('a');
  anchorElem.style.display = 'none';
  anchorElem.href = url;
  anchorElem.download = fileName;

  document.body.appendChild(anchorElem);
  anchorElem.click();

  document.body.removeChild(anchorElem);

  // On Edge, revokeObjectURL should be called only after
  // a.click() has completed, atleast on EdgeHTML 15.15048
  setTimeout(function () {
    window.URL.revokeObjectURL(url);
  }, 1000);
}

export function timeout(asyncFn, timeout = 7000, errorMessage = 'Ошибка ожидания') {
  return Promise.race([
    asyncFn,
    new Promise((_, reject) =>
      setTimeout(
        () =>
          reject({
            error: errorMessage,
          }),
        timeout
      )
    ),
  ]);
}

export function toApiDatetime(date: Date): string {
  if (!isValid(date)) {
    return null;
  }

  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
}

export function tryParseDate(datetime: number): Date {
  const date = new Date(datetime);

  if (!isValid(date)) {
    return null;
  }

  return date;
}

export const arrayBufferToBase64 = buffer => {
  const bytes = new Uint8Array(buffer);

  return base64js.fromByteArray(bytes);
};

export const getPasswordHash = (password: string) => {
  let byte = sha1(password, { asBytes: true });
  let newPasswordHash = arrayBufferToBase64(byte);
  return newPasswordHash;
};


export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
