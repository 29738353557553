import React, { useCallback, useState } from 'react';
import { set } from 'date-fns';

import {
  handleCancellableApiError,
  useCancellablePromise,
} from 'utils/cancellable-promise';
import { cleanObject, toApiDatetime } from 'utils/main';
import { genereateReport } from 'features/Reports/api';
import {
  CreateReportForm,
  CreateReportFormDTO,
  GetReportsParams,
  ReportsQueryParams,
} from 'features/Reports/types';
import { PAGE_SIZE } from 'features/Reports/constants';

import { GenerateReport } from './GenerateReport.component';

interface Props {
  getReports: (params: GetReportsParams) => void;
  queryParams: ReportsQueryParams;
}

export const GenerateReportContainer: React.FC<Props> = props => {
  const { cancellablePromise } = useCancellablePromise();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleGenerateReport = useCallback((formData, cb) => {
    setLoading(true);
    const reportData: CreateReportFormDTO = cleanObject({
      ...formData,
    });

    if (formData.startDate) {
      reportData.startDate = toApiDatetime(
        set(formData.startDate, {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
      );
    }

    if (formData.endDate) {
      reportData.endDate = toApiDatetime(
        set(formData.endDate, {
          hours: 23,
          minutes: 59,
          seconds: 59,
          milliseconds: 999,
        })
      );
    }

    cancellablePromise(
      genereateReport({
        ...reportData,
      })
    )
      .then(result => {
        cb && cb();
        props.getReports({ page: +props.queryParams.page || 1, pageSize: PAGE_SIZE });
      })
      .catch(
        handleCancellableApiError(error => {
          setError(error.error);
        })
      )
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <GenerateReport
      reportLoading={loading}
      onGenerateReport={handleGenerateReport}
      error={error}
    />
  );
};
