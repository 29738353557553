import React from 'react';
import { Typography } from '@abdt/ornament';

import { ReadOnlyField } from 'components/ReadOnlyField';

import { MerchantTerminal } from '../../types';

import useStyles from './TerminalInfoDetails.style';

interface Props {
  data: MerchantTerminal;
}

const TerminalInfoDetails: React.FC<Props> = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h4" className={classes.head}>
          Детали терминала
        </Typography>
        <ReadOnlyField className={classes.field} title="Номер">
          {data.terminalNumber}
        </ReadOnlyField>
        <ReadOnlyField className={classes.field} title="Терминал">
          {data.terminalName}
        </ReadOnlyField>
        <ReadOnlyField className={classes.field} title="МСС-код">
          {data.mcc}
        </ReadOnlyField>
        <ReadOnlyField className={classes.field} title="Статус">
          {data.isActive ? 'Активен' : 'Не активен'}
        </ReadOnlyField>
        <ReadOnlyField className={classes.field} title="Терминал ЕПЦ">
          {data.isEpcTerminal ? 'Да' : 'Нет'}
        </ReadOnlyField>
      </div>
    </>
  );
};

export default TerminalInfoDetails;
