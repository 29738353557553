import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@abdt/design-tokens';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    head: {
      marginBottom: 24,
    },
    content: {
      backgroundColor: '#fff',
      borderRadius: 4,
      margin: '20px 0 32px',
    },
    row: {
      height: '56px',

      '& .button': {
        visibility: 'hidden',
      },

      '&:hover': {
        '& .button': {
          visibility: 'visible',
        },
      },
    },
    pagination: {
      padding: '16px 12px',
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid #e1e1e1',
    },
    secLevel: {
      color: '#6c6c6c',
    },
    textEllipsis: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    button: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:active': {
        backgroundColor: 'transparent',
      },
    },
  })
);
