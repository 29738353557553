import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { toSearchParams } from 'utils/main';


import { TransactionDetails } from './TransactionDetails.component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/root-reducer';
import { fetchTransactionDetails } from 'features/Transactions/transactionsSlice';

interface Props {
  transactionId: string;
}

export const TransactionDetailsContainer: React.FC<Props> = ({ transactionId }) => {
  const location = useLocation();

  const { transactionDetails } = useSelector((state: RootState) => state.transactions);

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTransactionDetails(transactionId))
  }, [transactionId]);

  let returnQueryParams = '';

  if (location.state) {
    returnQueryParams = '?' + toSearchParams(location.state);
  }

  return (
    <TransactionDetails
      returnQueryParams={returnQueryParams}
      data={transactionDetails.data}
      error={transactionDetails.error}
      loading={transactionDetails.loading}
    />
  );
};
