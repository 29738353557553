/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import { ApiErrorConstruction } from 'types/api';
import { errorHandle } from 'utils/main';

import { getMerchantDetails } from './api';
import { MerchantDetail } from './types';

interface MerchantState {
  merchantDetails: {
    error: string;
    loading: boolean;
    loaded: boolean;
    data: MerchantDetail | undefined;
  };
}

export const initialState: MerchantState = {
  merchantDetails: {
    error: '',
    loading: true,
    loaded: false,
    data: undefined,
  },
};

const merchant = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    getMerchantDetailsStart(state) {
      state.merchantDetails.loading = true;
      state.merchantDetails.loaded = false;
    },
    getMerchantDetailsSuccess(state, action: PayloadAction<MerchantDetail>) {
      state.merchantDetails.loading = false;
      state.merchantDetails.loaded = true;
      state.merchantDetails.data = action.payload;
    },
    getMerchantDetailsFailure(state, action: PayloadAction<ApiErrorConstruction>) {
      state.merchantDetails.loading = false;
      state.merchantDetails.loaded = true;
      state.merchantDetails.error = action.payload.error;
    },
  },
});

export const {
  getMerchantDetailsStart,
  getMerchantDetailsSuccess,
  getMerchantDetailsFailure,
} = merchant.actions;
export default merchant.reducer;

export const fetchMerchantDetails = (merchantId: string): AppThunk => async dispatch => {
  try {
    dispatch(getMerchantDetailsStart());

    const result = await getMerchantDetails(merchantId);

    dispatch(getMerchantDetailsSuccess(result));
  } catch (rawError) {
    const error = errorHandle(rawError);
    dispatch(getMerchantDetailsFailure(error));
  }
};

export const silentFetchMerchantDetails = (
  merchantId: string
): AppThunk => async dispatch => {
  try {
    const result = await getMerchantDetails(merchantId);

    dispatch(getMerchantDetailsSuccess(result));
  } catch (rawError) {
    const error = errorHandle(rawError);
    dispatch(getMerchantDetailsFailure(error));
  }
};
