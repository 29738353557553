import React from 'react';
import { Accept, Close } from '@abdt/icons';
import { Button, Grid, Modal, Typography } from '@abdt/ornament';
import { useTheme } from '@material-ui/core';

import useStyles from './Result.style';

interface SuccessProps {
  onClose: VoidFunction;
}

const Success: React.FC<SuccessProps> = ({ onClose }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={3}
      className={classes.root}
    >
      <Grid item>
        <Accept
          size="large"
          color={theme.palette.primary.main}
          width="56px"
          height="56px"
        />
      </Grid>
      <Grid item>
        <Typography variant="h4" align="center">
          Название терминала успешно изменено
        </Typography>
      </Grid>
      <Grid item>
        <Button onClick={onClose}>Продолжить</Button>
      </Grid>
    </Grid>
  );
};
interface FailureProps {
  error?: string;
  onClose: VoidFunction;
}
const Failure: React.FC<FailureProps> = ({ error, onClose }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={3}
      className={classes.root}
    >
      <Grid item>
        <Close size="large" color={theme.palette.error.main} width="56px" height="56px" />
      </Grid>
      <Grid item>
        <Typography variant="h4">Ошибка сервиса</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" align="center">
          {error || 'Внутренняя ошибка сервиса. Обратитесь в поддержку.'}
        </Typography>
      </Grid>
      <Grid item>
        <Button onClick={onClose}>Закрыть</Button>
      </Grid>
    </Grid>
  );
};

interface Props {
  open: boolean;
  onClose: VoidFunction;
  success?: boolean;
  error?: string;
}

const Result: React.FC<Props> = ({ open, onClose, success, error }) => {
  return (
    <Modal open={open} onClose={onClose} transitionTimeout={100}>
      {open &&
        (success ? (
          <Success onClose={onClose} />
        ) : (
          <Failure onClose={onClose} error={error} />
        ))}
    </Modal>
  );
};

export default Result;
