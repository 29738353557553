import React from 'react';
import { useParams } from 'react-router-dom';

import { TransactionDetailsContainer } from 'features/Transactions/components/TransactionDetails';
import { HomeLayout } from 'layouts/Home';

interface Props {}

export const TransactionDetailsPage: React.FC<Props> = () => {
  const { transactionId } = useParams<{ transactionId: string }>();

  return (
    <HomeLayout>
      <TransactionDetailsContainer transactionId={transactionId} />
    </HomeLayout>
  );
};
