import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Paper, Typography } from '@abdt/ornament';

import { PageHead } from 'components/PageHead';
import { PageHeadTitle } from 'components/PageHeadTitle';
import { Container } from 'components/Container';

import { Filter } from '../Filter';
import { DataGrid } from '../DataGrid';
import {
  TransactionItem,
  TransactionsFilterForm,
  TransactionsQueryParams,
} from '../../types';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
  },
  dataGrid: {
    position: 'relative',
    backgroundColor: '#fff',
    borderRadius: 4,
    margin: '24px 0 32px',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  messageTitle: {
    fontSize: 18,
  },
  messageDescription: {
    fontSize: 16,
  },
  filter: {
    zIndex: 1,
    paddingTop: 24,
  },
}));

interface Props {
  queryParams: TransactionsQueryParams;
  filterFormData: TransactionsFilterForm;
  data: TransactionItem[];
  pageCount: number;
  error: string;
  loading: boolean;
  onFilterSubmit: (formData: TransactionsFilterForm) => void;
}

export const Transactions: React.FC<Props> = ({
  queryParams,
  filterFormData,
  data,
  pageCount,
  error,
  loading,
  onFilterSubmit,
}) => {
  const classes = useStyles();

  const [filterOpen, setFilterOpen] = useState(false);

  const renderContent = useMemo(() => {
    if (loading) {
      return (
        <div className={classes.loader}>
          <CircularProgress data-testid="loader" size={40} />
        </div>
      );
    } else if (error) {
      return (
        <div className={classes.message}>
          <Typography className={classes.messageTitle} variant="h4" color="error">
            Ошибка получения данных
          </Typography>
        </div>
      );
    } else if (data.length === 0) {
      return (
        <div className={classes.message}>
          <Typography className={classes.messageTitle} variant="h4" gutterBottom>
            Транзакции не найдены
          </Typography>
          <Typography className={classes.messageDescription} variant="body1">
            Попробуйте изменить параметры фильтра
          </Typography>
        </div>
      );
    } else {
      return (
        <Container>
          <Paper
            data-testid="transactions-container"
            className={classes.dataGrid}
            elevation={0}
          >
            <DataGrid queryParams={queryParams} data={data} pageCount={pageCount} />
          </Paper>
        </Container>
      );
    }
  }, [loading, data, error, queryParams, pageCount]);

  return (
    <section className={classes.root}>
      <PageHead>
        <PageHeadTitle>Транзакции</PageHeadTitle>
      </PageHead>
      <div className={classes.content}>
        <Filter
          defaultValues={filterFormData}
          onSubmit={onFilterSubmit}
          className={classes.filter}
          open={filterOpen}
          onOpen={setFilterOpen}
        />
        {renderContent}

        {filterOpen && (
          <div className={classes.overlay} onClick={() => setFilterOpen(false)}></div>
        )}
      </div>
    </section>
  );
};
