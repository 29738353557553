import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button, CircularProgress } from '@abdt/ornament';
import { useForm } from 'react-hook-form';

import { FormDataInfo } from '../types';

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    '& + &': {
      marginTop: 16,
    },
  },
  action: {
    marginTop: 24,
  },
  error: {
    marginTop: 16,
  },
}));

interface Props {
  onSubmit: (formData: FormDataInfo) => void;
  loading: boolean;
  error: string;
}

export const LoginStep: React.FC<Props> = ({ onSubmit, loading, error }) => {
  const classes = useStyles();

  const { register, handleSubmit, errors } = useForm<FormDataInfo>({});

  return (
    <>
      <Typography id="head" variant="h3" gutterBottom>
        Авторизация
      </Typography>
      <form
        aria-labelledby="head"
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
      >
        <TextField
          inputProps={{
            'data-testid': 'username',
          }}
          className={classes.field}
          type="text"
          name="username"
          autoComplete="username"
          label="Логин"
          error={Boolean(errors.username)}
          inputRef={register({
            required: true,
          })}
        />
        <TextField
          inputProps={{
            'data-testid': 'password',
          }}
          className={classes.field}
          type="password"
          name="password"
          autoComplete="current-password"
          label="Пароль"
          error={Boolean(errors.password)}
          inputRef={register({
            required: true,
          })}
        />
        {error && (
          <Typography className={classes.error} variant="body1" color="error">
            {error}
          </Typography>
        )}
        <Button
          className={classes.action}
          startIcon={loading && <CircularProgress size={20} />}
          disabled={loading}
          type="submit"
          fullWidth
        >
          Войти
        </Button>
      </form>
    </>
  );
};
