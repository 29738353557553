import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { format } from 'date-fns';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Chip,
  Grid,
} from '@abdt/ornament';
import { colors } from '@abdt/design-tokens';

import { CurrencyList } from 'features/Transactions/currency';

import { BadTransactionTooltip } from '../BadTransactionTooltip';
import { TransactionDoc } from '../../types';

const useStyles = makeStyles(() => ({
  root: {},
  head: {
    marginBottom: 24,
  },
  secLevel: {
    color: '#6c6c6c',
  },
  tooltip: {
    backgroundColor: colors.neonGray850,
    boxShadow: 'none',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    padding: '8px 12px',
  },
  tooltipArrow: {
    color: colors.neonGray850,
  },
  listIcon: {
    display: 'flex',
    alignItems: 'center',

    '&:hover svg': {
      color: '#00B2A9',
    },
  },
  tableCell: {
    padding: 10,
  },
  tableHeadCell: {
    padding: '10px 10px 4px',
  },
}));

interface Props {
  docs: TransactionDoc[];
}

export const TransactionDocs: React.FC<Props> = ({ docs }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <Typography variant="body1" bold className={classes.head}>
        История операций
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>Дата и время</TableCell>
              <TableCell className={classes.tableHeadCell}>
                RRN и код авторизации
              </TableCell>
              <TableCell className={classes.tableHeadCell}>Сумма</TableCell>
              <TableCell className={classes.tableHeadCell}>Тип</TableCell>
              <TableCell className={classes.tableHeadCell}>Статус</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="docs-body">
            {docs.map(item => {
              return (
                <TableRow key={item.id}>
                  <TableCell className={classes.tableCell}>
                    <div>{format(new Date(item.date), 'dd.MM.yyyy')}</div>
                    <div className={classes.secLevel}>
                      {format(new Date(item.date), 'HH:mm')}
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div>{item.refNumber}</div>
                    <div className={classes.secLevel}>{item.authCode}</div>
                  </TableCell>
                  <TableCell className={classes.tableCell} data-testid="local-currency">
                    {CurrencyList.includes(item.currency)
                      ? item.amount.toLocaleString('ru-RU', {
                          style: 'currency',
                          currency: item.currency,
                        })
                      : item.amount.toLocaleString('ru-RU', {
                          minimumFractionDigits: 2,
                        })}
                  </TableCell>
                  <TableCell className={classes.tableCell}>{item.type}</TableCell>
                  <TableCell className={classes.tableCell}>
                    <Grid container alignItems="center" wrap="nowrap" spacing={2}>
                      <Grid item>
                        <Chip
                          style={{
                            backgroundColor: item.state.color,
                            color: theme.palette.getContrastText(item.state.color),
                          }}
                          label={item.state.name}
                        />
                      </Grid>
                      <Grid item>
                        {item.returnCode ? (
                          <BadTransactionTooltip
                            returnCode={item.returnCode}
                            description={item.errorDescription}
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
