import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Link } from '@material-ui/core';

import { HeaderContainer } from 'components/Header';
import { Container } from 'components/Container';

const useStyles = makeStyles(() => ({
  notFound: {
    marginTop: 32,
    width: '40vh',
    minWidth: 300,
  },
  label: {
    fontWeight: 500,
  },
  forward: {
    marginTop: 15,
  },
}));

export const NotFound: React.FC = () => {
  const classes = useStyles();

  return (
    <Container>
      <Card className={classes.notFound} variant="outlined">
        <CardContent>
          <Typography className={classes.label} variant="body1">
            Страница не найдена
          </Typography>
          <Typography className={classes.forward} variant="body1">
            <Link href="https://www.akbars.ru/">Перейти в Ак Барс Банк</Link>
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};
