import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@abdt/ornament';

import { Container } from 'components/Container';

const useStyles = makeStyles(() => ({
  root: {
    background: '#fff',
    padding: 'calc(8px * var(--vertical-spacing, 3)) 0',
  },
}));

interface Props {
  verticalSpacing?: number;
  children: React.ReactNode;
}

export const PageHead: React.FC<Props> = memo(({ children, verticalSpacing = 3 }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{ ['--vertical-spacing' as any]: verticalSpacing }}
    >
      <Container>{children}</Container>
    </div>
  );
});
