export enum ReportStatus {
  InProgress = 'InProgress',
  Completed = 'Completed',
  Error = 'Error',
}

export interface ReportItem {
  id: string;
  fileName: string;
  reportName: string;
  contentType: string;
  reportStatus: ReportStatus;
  length: 0;
  created: string;
}

export interface CreateReportForm {
  startDate?: string | number;
  endDate?: string | number;
  terminalId?: string;
}
export interface CreateReportFormDTO {
  startDate?: string;
  endDate?: string;
  terminalId?: string;
}

export interface DownloadReportParams {
  reportId: string;
}

export interface FilterTerminal {
  id: string;
  terminalNumber: string;
  terminalName: string;
  isActive: boolean;
}

export interface FilterStatus {
  number: number;
  name: string;
}

export interface RefundInitResponse {
  operationToken: string;
}

export interface GetReportsParams {
  page: number;
  pageSize: number;
}

export interface ReportsQueryParams {
  page?: string;
}
