import { combineReducers } from '@reduxjs/toolkit';

import transactionsReducer from 'features/Transactions/transactionsSlice';
import loginReducer from 'features/Login/loginSlice';
import merchantReducer from 'features/Merchant/merchantSlice';


import profileReducer from './features/profile';

const rootReducer = combineReducers({
  login: loginReducer,
  profile: profileReducer,
  transactions: transactionsReducer,
  merchant: merchantReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
