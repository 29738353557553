import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@abdt/ornament';
import { Refund } from '../types';

const useStyles = makeStyles(() => ({
  description: {
    marginTop: 8,
  },
  form: {
    marginTop: 16,
  },
  actions: {
    marginTop: 28,
  },
}));

const titleByRefundType = {
  [Refund.refund]: 'Возврат выполнен успешно!',
  [Refund.cancel]: 'Отмена выполнена успешно!'
}
interface Props {
  className?: string;
  onCancel: () => void;
  refundType: Refund;
}

export const ChargebackSuccess: React.FC<Props> = ({ className, onCancel, refundType }) => {
  const classes = useStyles();

  return (
    <div className={className}>
      <Typography variant="h4">{titleByRefundType[refundType]}</Typography>
      <Grid className={classes.actions} container item spacing={1}>
        <Grid item>
          <Button fullWidth variant="outlined" onClick={onCancel}>
            Закрыть
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
