import React from 'react';

import { AuthLayout } from 'layouts/Auth';
import { LoginContainer } from 'features/Login';

interface Props {}

export const LoginPage: React.FC<Props> = props => {
  return (
    <AuthLayout>
      <LoginContainer />
    </AuthLayout>
  );
};
