import React from 'react';
import { useParams } from 'react-router-dom';

import TerminalInfo from 'features/Merchant/components/TerminalInfo';
import { HomeLayout } from 'layouts/Home';

interface Props {}

export const TerminalDetailsPage: React.FC<Props> = () => {
  const { contractId, terminalId } = useParams<{
    contractId: string;
    terminalId: string;
  }>();

  return (
    <HomeLayout>
      <TerminalInfo contractId={contractId} terminalId={terminalId} />
    </HomeLayout>
  );
};
