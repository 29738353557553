import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { HeaderContainer } from 'components/Header';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: '72px 1fr',
    height: '100%',
    minHeight: '100vh',
  },
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface Props {}

export const AuthLayout: React.FC<Props> = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HeaderContainer />
      <main className={classes.main}>{props.children}</main>
    </div>
  );
};
