import { apiGet, apiPost, apiPut } from 'services/ajax';
import { jsonResponseHandler } from 'services/request';
import { PageContent } from 'types/api';

import {
  GetMerchantParams,
  ITerminalUpdateNameParams,
  Merchant,
  MerchantDetail,
  SearchMerchantParams,
} from './types';

export function getMerchantDetails(merchantId: string) {
  return jsonResponseHandler<MerchantDetail>(() =>
    apiGet({
      baseUrl: '/api',
      urn: `/internal/Merchant/${merchantId}`,
      public: true,
      jsonRequest: true,
    })
  ).then(json => json.result);
}

export function getMerchants(params: GetMerchantParams) {
  return jsonResponseHandler<PageContent<Merchant>>(() =>
    apiPost({
      baseUrl: '/api',
      urn: `/Merchant/connected`,
      body: params,
      public: true,
      jsonRequest: true,
    })
  ).then(json => json.result);
}

export function searchMerchants(params: SearchMerchantParams) {
  return jsonResponseHandler<Merchant[]>(() =>
    apiPost({
      baseUrl: '/api',
      urn: `/Merchant/search`,
      body: params,
      public: true,
      jsonRequest: true,
    })
  ).then(json => json.result);
}

export function enableMerchant(merchantId: string) {
  return jsonResponseHandler<PageContent<Merchant>>(() =>
    apiPost({
      baseUrl: '/api',
      urn: `/Merchant/enable/${merchantId}`,
      public: true,
      jsonRequest: true,
    })
  ).then(json => json.result);
}

export function updateTerminalName(
  terminalId: string,
  params: ITerminalUpdateNameParams
) {
  return jsonResponseHandler<unknown>(() =>
    apiPut({
      baseUrl: '/api',
      urn: `/Terminal/changeName/${terminalId}`,
      body: params,
      jsonRequest: true,
    })
  ).then(json => json.result);
}
