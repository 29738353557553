import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import TerminalList from 'features/Merchant/components/TerminalList';
import { HomeLayout } from 'layouts/Home';
import { decodeSearchParams } from 'utils/main';
import { MerchantInfoQueryParams } from 'features/Merchant/types';

interface Props {}

export const ContractDetailsPage: React.FC<Props> = () => {
  const { search } = useLocation();
  const { contractId } = useParams<{ contractId: string }>();

  const queryParams = decodeSearchParams<MerchantInfoQueryParams>(search);

  return (
    <HomeLayout>
      <TerminalList queryParams={queryParams} contractId={contractId} />
    </HomeLayout>
  );
};
