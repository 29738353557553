import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      padding: theme.spacing(5),
      width: '440px',
    },
    terminalDetails: {
      display: 'flex',
      flexDirection: 'column',
    },
    closeIcon: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(3),
      padding: 0,
    },
  })
);
