import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@abdt/ornament';

import { Otp } from 'components/Otp';
import { OtpFormData } from 'types/otp';
import { Refund } from '../types';

const useStyles = makeStyles(() => ({
  description: {
    marginTop: 8,
  },
}));

const descriptionByRefundType = {
  [Refund.refund]: 'Для подтверждения возврата введите СМС-код',
  [Refund.cancel]: 'Для подтверждения отмены введите СМС-код'
}

interface Props {
  className?: string;
  error: string;
  loading: boolean;
  onSubmit: (formData: OtpFormData) => void;
  sendOtpErrorReset: VoidFunction;

  resendOtpError: string;
  resendOtpLoading: boolean;
  resendOtpLastDate: number;
  onResendOtp: VoidFunction;
  resendOtpErrorReset: VoidFunction;

  refundType: Refund;
}

export const ChargebackConfirm: React.FC<Props> = ({
  className,
  error,
  loading,
  onSubmit,
  sendOtpErrorReset,
  resendOtpError,
  resendOtpLoading,
  resendOtpLastDate,
  onResendOtp,
  resendOtpErrorReset,
  refundType,
}) => {
  const classes = useStyles();

  return (
    <div className={className}>
      <Otp
        error={error}
        loading={loading}
        onSubmit={onSubmit}
        sendOtpErrorReset={sendOtpErrorReset}
        resendOtpError={resendOtpError}
        resendOtpLoading={resendOtpLoading}
        resendOtpLastDate={resendOtpLastDate}
        onResend={onResendOtp}
        resendOtpErrorReset={resendOtpErrorReset}
        text={
          <>
            <Typography variant="h4">Подтверждение</Typography>
            <Typography className={classes.description} variant="body1">
              {descriptionByRefundType[refundType]}
            </Typography>
          </>
        }
      />
    </div>
  );
};
