import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@abdt/ornament';

interface Props {
  className?: string;
  title: string;
  titleWidth?: number;
  children: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 14,
    lineHeight: '20px',
  },
}));

export const ReadOnlyField: React.FC<Props> = ({
  className,
  title,
  titleWidth = 6,
  children,
}) => {
  const classes = useStyles();
  const valueWidth = 12 - titleWidth;

  return (
    <Grid container className={className}>
      <Grid item xs={titleWidth as any}>
        <Typography className={classes.text} color="textSecondary" variant="body1">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={valueWidth as any}>
        <Typography className={classes.text} variant="body1">
          {children}
        </Typography>
      </Grid>
    </Grid>
  );
};
