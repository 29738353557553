import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Chip,
  Grid,
} from '@abdt/ornament';
import { colors } from '@abdt/design-tokens';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { PaginationLink } from 'components/PaginationLink';

import { CurrencyList } from '../../currency';
import { TransactionItem, TransactionsQueryParams } from '../../types';
import { BadTransactionTooltip } from '../BadTransactionTooltip';

const useStyles = makeStyles(() => ({
  content: {
    backgroundColor: '#fff',
    borderRadius: 4,
    margin: '20px 0 32px',
  },
  row: {
    textDecoration: 'none',

    '&:hover': {
      backgroundColor: colors.gray50,
    },
  },
  pagination: {
    padding: '16px 12px',
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #e1e1e1',
  },
  secLevel: {
    color: '#6c6c6c',
  },
  textEllipsis: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

interface Props {
  data: TransactionItem[];
  pageCount: number;
  queryParams: TransactionsQueryParams;
}

export const DataGrid: React.FC<Props> = ({ data, pageCount, queryParams }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Дата и время</TableCell>
              <TableCell>Номер заказа</TableCell>
              <TableCell>RRN и код авторизации</TableCell>
              <TableCell>Сумма</TableCell>
              <TableCell>Возврат</TableCell>
              <TableCell>Комиссия</TableCell>
              <TableCell>Код ответа</TableCell>
              <TableCell>Терминал</TableCell>
              <TableCell>Карта</TableCell>
              <TableCell>Статус</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(item => {
              return (
                <TableRow
                  className={classes.row}
                  component={Link}
                  to={{
                    pathname: `/transactions/${item.id}/`,
                    state: queryParams,
                  }}
                  key={item.id}
                >
                  <TableCell>
                    <div>{format(new Date(item.transactionDate), 'dd.MM.yyyy')}</div>
                    <div className={classes.secLevel}>
                      {format(new Date(item.transactionDate), 'HH:mm')}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={classes.textEllipsis} style={{ maxWidth: '10vw' }}>
                      {item.orderId}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div>{item.refNumber}</div>
                    <div className={classes.secLevel}>{item.authCode}</div>
                  </TableCell>
                  <TableCell>
                    {CurrencyList.includes(item.currency)
                      ? item.amount.toLocaleString('ru-RU', {
                          style: 'currency',
                          currency: item.currency,
                        })
                      : item.amount.toLocaleString('ru-RU', {
                          minimumFractionDigits: 2,
                        })}
                  </TableCell>
                  <TableCell>
                    {CurrencyList.includes(item.currency)
                      ? item.refundAmount.toLocaleString('ru-RU', {
                          style: 'currency',
                          currency: item.currency,
                        })
                      : item.refundAmount.toLocaleString('ru-RU', {
                          minimumFractionDigits: 2,
                        })}
                  </TableCell>
                  <TableCell>
                    {CurrencyList.includes(item.currency)
                      ? item.fee?.toLocaleString('ru-RU', {
                          style: 'currency',
                          currency: item.currency,
                        })
                      : item.fee?.toLocaleString('ru-RU', {
                          minimumFractionDigits: 2,
                        })}
                  </TableCell>
                  <TableCell>{item.returnCode}</TableCell>
                  <TableCell>
                    <div>{item.terminalWay4}</div>
                    <div
                      className={classNames(classes.secLevel, classes.textEllipsis)}
                      style={{ maxWidth: '10vw' }}
                    >
                      {item.alternateName || item.shopName}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div>{item.card}</div>
                    <div className={classes.secLevel}>{item.paymentSystem}</div>
                  </TableCell>
                  <TableCell>
                    <Grid container alignItems="center" wrap="nowrap" spacing={2}>
                      <Grid item>
                        <Chip
                          style={{
                            backgroundColor: item.status.color,
                            color: theme.palette.getContrastText(item.status.color),
                          }}
                          label={item.status.name}
                        />
                      </Grid>
                      <Grid item>
                        {item.returnCode ? (
                          <BadTransactionTooltip
                            returnCode={item.returnCode}
                            description={item.errorDescription}
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className={classes.pagination}>
        <Typography variant="body2">Страница:</Typography>
        <PaginationLink
          queryParams={queryParams}
          rootPath="transactions"
          count={pageCount}
        />
      </div>
    </>
  );
};
