import React, { useRef } from 'react';
import { FormControl, InputLabel, Select } from '@abdt/ornament';
import { FormHelperText, SelectProps } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import { FormFieldProps } from 'types/form-field';

type Props = Omit<SelectProps, 'onChange' | 'value'> & {
  label: string;
  helperText?: string;
  formFieldProps: FormFieldProps;
};

export const SelectFormField: React.FC<Props> = React.memo(props => {
  const {
    formFieldProps: { control, clearErrors, errors, validationRules },
    name,
    label,
    helperText,
    children,
    ...other
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Controller
      control={control}
      name={name}
      rules={validationRules}
      render={({ onChange, value }) => {
        return (
          <FormControl fullWidth error={Boolean(errors[name])} size="small">
            <InputLabel>{label}</InputLabel>
            <Select
              {...other}
              name={name}
              value={value}
              onChange={event => {
                clearErrors(name);
                onChange(event.target.value);
              }}
            >
              {children}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
        );
      }}
      onFocus={() => inputRef.current?.focus()}
    />
  );
});
