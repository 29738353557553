/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo } from 'react';
import {
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Breadcrumbs,
  Link,
} from '@abdt/ornament';
import { Link as RouterLink } from 'react-router-dom';

import { PageHead } from 'components/PageHead';
import { Container } from 'components/Container';

import { MerchantContracts, MerchantTerminal } from '../../types';
import ContractInfo from '../ContractInfoDetails';

import DataGrid from './DataGrid';
import useStyles from './TerminalList.style';

interface Props {
  data: MerchantTerminal[];
  error: string;
  loading: boolean;
  merchantContract?: MerchantContracts;
}

const TerminalList: React.FC<Props> = ({ loading, error, data, merchantContract }) => {
  const classes = useStyles();

  const renderContent = useMemo(() => {
    if (loading) {
      return (
        <Container className={classes.container}>
          <div className={classes.loader}>
            <CircularProgress size={40} />
          </div>
        </Container>
      );
    }
    if (error) {
      return (
        <Container className={classes.container}>
          <div className={classes.message}>
            <Typography
              className={classes.messageTitle}
              variant="h4"
              color="error"
              gutterBottom
            >
              Ошибка получения данных
            </Typography>
          </div>
        </Container>
      );
    }
    if (data && merchantContract) {
      return (
        <>
          <Container className={classes.container}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.content}>
                  <ContractInfo data={merchantContract} />
                </Paper>
              </Grid>
              <Grid item xs={8}>
                <Paper elevation={0} className={classes.content}>
                  <DataGrid data={data} />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </>
      );
    }
    return null;
  }, [loading, data, error, merchantContract]);

  return (
    <>
      <section className={classes.root}>
        <PageHead verticalSpacing={3}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/merchant" component={RouterLink}>
                  Об организации
                </Link>
                <Typography color="textPrimary" variant="body1">
                  Cчет {merchantContract?.contractName}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </PageHead>
        {renderContent}
      </section>
    </>
  );
};

export default TerminalList;
