import { apiGet, apiPost } from 'services/ajax';
import { blobResponseHandler, jsonResponseHandler } from 'services/request';
import { PageContent } from 'types/api';

import {
  CreateReportForm,
  CreateReportFormDTO,
  DownloadReportParams,
  GetReportsParams,
  ReportItem,
} from './types';

export function getReports(params: GetReportsParams) {
  return jsonResponseHandler<PageContent<ReportItem>>(() =>
    apiPost({
      baseUrl: '/api',
      urn: `/Report/userReports`,
      body: params,
      public: false,
      jsonRequest: true,
    })
  ).then(json => json.result);
}

export function downloadReportS3(params: DownloadReportParams) {
  return blobResponseHandler(() =>
    apiGet({
      baseUrl: '/api',
      urn: `/Report/download`,
      queryParams: params,
      public: false,
      jsonRequest: true,
    })
  ).then(result => result);
}

export function genereateReport(formData: CreateReportFormDTO) {
  return jsonResponseHandler(() =>
    apiPost({
      baseUrl: '/api',
      urn: `/Report/TerminalState`,
      body: formData,
      public: false,
      jsonRequest: true,
    })
  ).then(json => json.result);
}
