import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeLayout } from '@abdt/ornament';
import { Provider } from 'react-redux';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { LocalizationProvider } from '@material-ui/pickers';
import ruLocale from 'date-fns/locale/ru';
import { SnackbarProvider } from 'notistack';

import { theme } from 'utils/custom-theme';

import './index.css';
import store from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';

const SnackbarAnchorOrigin = { vertical: 'bottom', horizontal: 'left' };

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <ThemeLayout theme={theme}>
        <Provider store={store}>
          <LocalizationProvider dateAdapter={DateFnsUtils} locale={ruLocale}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={SnackbarAnchorOrigin as any}
              autoHideDuration={10000}
            >
              <App />
            </SnackbarProvider>
          </LocalizationProvider>
        </Provider>
      </ThemeLayout>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
