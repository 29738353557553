import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Grid, Typography } from '@abdt/ornament';
import { useForm } from 'react-hook-form';

import { AmountFormField } from 'components/AmountField';
import { Transaction } from 'features/Transactions/types';

import { ChargebackFormData, Refund } from '../types';

const useStyles = makeStyles(theme => ({
  description: {
    marginTop: 8,
  },
  form: {
    marginTop: 16,
  },
  actions: {
    marginTop: 28,
  },
  error: {
    marginTop: 24,
    color: theme.palette.error.main,
    padding: 10,
    fontSize: 11,
    lineHeight: '13px',
    textShadow: 'none',
    zIndex: 10,
    whiteSpace: 'pre-line',
  },
}));

const titleByRefundType = {
  [Refund.refund]: 'Осуществление возврата',
  [Refund.cancel]: 'Осуществление отмены'
}

const descriptionByRefundType = {
  [Refund.refund]: 'Вы можете сделать частичный возврат или вернуть сумму целиком',
  [Refund.cancel]: 'Вы можете вернуть сумму только целиком'
}

const errorMaxByRefundType = {
  [Refund.refund]: 'Сумма для возврата не может превышать сумму транзакции',
  [Refund.cancel]: 'Сумма для отмены не может превышать сумму транзакции'
}

const labelByRefundType = {
  [Refund.refund]: 'Сумма для возврата, ₽',
  [Refund.cancel]: 'Сумма для отмены, ₽'
}

interface Props {
  className?: string;
  onSubmit: (formData: ChargebackFormData) => void;
  onCancel: () => void;
  transactionDetail: Transaction;
  error?: string;
  loading: boolean;
  refundType: Refund
}

export const ChargebackForm: React.FC<Props> = ({
  className,
  onSubmit,
  onCancel,
  transactionDetail,
  error,
  loading,
  refundType,
}) => {
  const classes = useStyles();

  const { handleSubmit, errors, control, clearErrors } = useForm<ChargebackFormData>({
    defaultValues: {
      amount: transactionDetail?.amount,
    },
  });

  const formProps = {
    errors,
    control,
    clearErrors,
  };

  let errorText;
  if (Boolean(errors.amount)) {
    errorText = errors.amount.message || '';
  }
  return (
    <div className={className}>
      <Typography variant="h4">{titleByRefundType[refundType]}</Typography>
      <Typography variant="body2" className={classes.description}>
        {descriptionByRefundType[refundType]}
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <AmountFormField
          formFieldProps={{
            ...formProps,
            validationRules: {
              required: true,
              max: {
                value: transactionDetail?.amount,
                message: errorMaxByRefundType[refundType],
              },
            },
          }}
          disabled={refundType === Refund.cancel}
          fullWidth={true}
          type="text"
          size="small"
          label={labelByRefundType[refundType]}
          name="amount"
          helperText={errorText}
        />

        {error && <div className={classes.error}>{error}</div>}

        <Grid className={classes.actions} container item spacing={1}>
          <Grid item>
            <Button
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
              fullWidth
              type="submit"
            >
              Вернуть деньги
            </Button>
          </Grid>
          <Grid item>
            <Button disabled={loading} fullWidth variant="outlined" onClick={onCancel}>
              Отменить
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
