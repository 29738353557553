import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getFilterPaymentSystems,
  getFilterStatuses,
  getTerminals,
  getTransactionDetails,
} from 'services/api';
import { AppThunk } from 'store';
import { ApiErrorConstruction } from 'types/api';
import { errorHandle, safeArrayCheck } from 'utils/main';

import { FilterStatus, FilterTerminal, Transaction } from './types';

export interface ProfileInfo {
  name: string;
}

interface TransactionsState {
  filterPaymentSystems: {
    error: string;
    loading: boolean;
    loaded: boolean;
    data: string[];
  };
  filterStatuses: {
    error: string;
    loading: boolean;
    loaded: boolean;
    data: FilterStatus[];
  };
  filterTerminals: {
    error: string;
    loading: boolean;
    loaded: boolean;
    data: FilterTerminal[];
  };
  transactionDetails: {
    error: string;
    loading: boolean;
    loaded: boolean;
    data: Transaction;
  };
}

export const initialState: TransactionsState = {
  filterPaymentSystems: {
    error: null,
    loading: false,
    loaded: false,
    data: null,
  },
  filterStatuses: {
    error: null,
    loading: false,
    loaded: false,
    data: null,
  },
  filterTerminals: {
    error: null,
    loading: false,
    loaded: false,
    data: null,
  },
  transactionDetails: {
    error: null,
    loading: true,
    loaded: false,
    data: null,
  },
};

const transactions = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    getFilterStatusesStart(state) {
      state.filterStatuses.loading = true;
      state.filterStatuses.loaded = false;
    },
    getFilterStatusesSuccess(state, action: PayloadAction<FilterStatus[]>) {
      state.filterStatuses.loading = false;
      state.filterStatuses.loaded = true;
      state.filterStatuses.data = action.payload;
    },
    getFilterStatusesFailure(state, action: PayloadAction<ApiErrorConstruction>) {
      state.filterStatuses.loading = false;
      state.filterStatuses.loaded = true;
      state.filterStatuses.error = action.payload.error;
    },

    getFilterPaymentSystemsStart(state) {
      state.filterPaymentSystems.loading = true;
      state.filterPaymentSystems.loaded = false;
    },
    getFilterPaymentSystemsSuccess(state, action: PayloadAction<string[]>) {
      state.filterPaymentSystems.loading = false;
      state.filterPaymentSystems.loaded = true;
      state.filterPaymentSystems.data = action.payload;
    },
    getFilterPaymentSystemsFailure(state, action: PayloadAction<ApiErrorConstruction>) {
      state.filterPaymentSystems.loading = false;
      state.filterPaymentSystems.loaded = true;
      state.filterPaymentSystems.error = action.payload.error;
    },

    getFilterTerminalsStart(state) {
      state.filterTerminals.loading = true;
      state.filterTerminals.loaded = false;
    },
    getFilterTerminalsSuccess(state, action: PayloadAction<FilterTerminal[]>) {
      state.filterTerminals.loading = false;
      state.filterTerminals.loaded = true;
      state.filterTerminals.data = action.payload;
    },
    getFilterTerminalsFailure(state, action: PayloadAction<ApiErrorConstruction>) {
      state.filterTerminals.loading = false;
      state.filterTerminals.loaded = true;
      state.filterTerminals.error = action.payload.error;
    },
    getTransactionDetailsStart(state) {
      state.transactionDetails.loading = true;
      state.transactionDetails.loaded = false;
    },
    getTransactionDetailsSuccess(state, action: PayloadAction<Transaction>) {
      state.transactionDetails.loading = false;
      state.transactionDetails.loaded = true;
      state.transactionDetails.data = action.payload;
    },
    getTransactionDetailsFailure(state, action: PayloadAction<ApiErrorConstruction>) {
      state.transactionDetails.loading = false;
      state.transactionDetails.loaded = true;
      state.transactionDetails.error = action.payload.error;
    },
  },
});

export const {
  getFilterPaymentSystemsFailure,
  getFilterPaymentSystemsStart,
  getFilterPaymentSystemsSuccess,

  getFilterStatusesStart,
  getFilterStatusesSuccess,
  getFilterStatusesFailure,

  getFilterTerminalsStart,
  getFilterTerminalsSuccess,
  getFilterTerminalsFailure,

  getTransactionDetailsStart,
  getTransactionDetailsSuccess,
  getTransactionDetailsFailure,
} = transactions.actions;
export default transactions.reducer;

export const fetchFilterStatuses = (): AppThunk => async dispatch => {
  try {
    dispatch(getFilterStatusesStart());

    const result = await getFilterStatuses();

    dispatch(getFilterStatusesSuccess(result));
  } catch (rawError) {
    const error = errorHandle(rawError);
    dispatch(getFilterStatusesFailure(error));
  }
};

export const fetchFilterPaymentSystems = (): AppThunk => async dispatch => {
  try {
    dispatch(getFilterPaymentSystemsStart());

    const result = await getFilterPaymentSystems();

    dispatch(getFilterPaymentSystemsSuccess(result));
  } catch (rawError) {
    const error = errorHandle(rawError);
    dispatch(getFilterPaymentSystemsFailure(error));
  }
};

export const fetchFilterTerminals = (): AppThunk => async dispatch => {
  try {
    dispatch(getFilterTerminalsStart());

    const result = await getTerminals();

    dispatch(getFilterTerminalsSuccess(result.data));
  } catch (rawError) {
    const error = errorHandle(rawError);
    dispatch(getFilterTerminalsFailure(error));
  }
};

export const fetchTransactionDetails = (
  transactionId: string
): AppThunk => async dispatch => {
  try {
    dispatch(getTransactionDetailsStart());

    const result = await getTransactionDetails(transactionId);

    dispatch(getTransactionDetailsSuccess(result));
  } catch (rawError) {
    const error = errorHandle(rawError);
    dispatch(getTransactionDetailsFailure(error));
  }
};

export const repeatFetchTransactionDetails = (transactionId: string): AppThunk => async (
  dispatch,
  getState
) => {
  const {
    transactions: { transactionDetails },
  } = getState();
  const oldTransactionRefund = JSON.stringify(transactionDetails.data.refund);

  let count = 0;

  const repeatFetch = async () => {
    try {
      if (count > 2) {
        return;
      }
      const result = await getTransactionDetails(transactionId);
      const newTransactionRefund = JSON.stringify(result.refund);
      if (oldTransactionRefund !== newTransactionRefund) {
        dispatch(getTransactionDetailsSuccess(result));
      } else {
        count++;
        setTimeout(() => {
          repeatFetch();
        }, 3000);
      }
    } catch (rawError) {}
  };

  repeatFetch();
};
