import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Chip } from '@abdt/ornament';

import { ReportStatus } from '../../types';

interface Props {
  status: ReportStatus;
}

interface State {
  color: string;
  name: string;
}

const initialState = {
  name: '',
  color: '#d3d3d3',
};

export const Status: React.FC<Props> = ({ status }) => {
  const theme = useTheme();
  const [state, setstate] = useState<State>(initialState);

  useEffect(() => {
    switch (status) {
      case ReportStatus.Completed:
        setstate({ name: 'Сформирован', color: theme.palette.success.main });
        break;
      case ReportStatus.Error:
        setstate({ name: 'Ошибка', color: theme.palette.error.main });
        break;
      case ReportStatus.InProgress:
        setstate({ name: 'Формируется', color: theme.palette.warning.main });
        break;

      default:
        setstate(initialState);
        break;
    }
  }, [status]);

  return (
    <Chip
      style={{
        backgroundColor: state.color,
        color: theme.palette.getContrastText(state.color),
      }}
      label={state.name}
    />
  );
};
