import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  accountLoginConfirm,
  accountLoginInit,
  confirmOtp,
  resendOtp,
} from 'services/api';
import { setAuthToken } from 'services/token';
import { ApiErrorConstruction } from 'types/api';
import { errorHandle, getPasswordHash } from 'utils/main';
import { AppThunk } from 'store';

export type LoginSteps = 'phone' | 'otp';

interface LoginInitSuccess {
  operationId: string;
  phoneNumber: string;
}

interface LoginState {
  step: LoginSteps;
  phoneNumber: string;
  operationId: string;
  loginStep: {
    loading: boolean;
    error: string;
  };
  otpStep: {
    success: boolean;
    loading: boolean;
    error: string;
    errorCode?: number;
  };
  confirmStep: {
    success: boolean;
    loading: boolean;
    error: string;
  };
  resendOtp: {
    loading: boolean;
    error: string;
    lastDate: number;
    errorCode?: number;
  };
}

const initialState: LoginState = {
  step: 'phone',
  phoneNumber: null,
  operationId: null,
  loginStep: {
    loading: false,
    error: null,
  },
  otpStep: {
    success: false,
    loading: false,
    error: null,
  },
  confirmStep: {
    success: false,
    loading: false,
    error: null,
  },
  resendOtp: {
    loading: false,
    error: null,
    lastDate: null,
  },
};

const login = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setPhoneNumber(state, action: PayloadAction<string>) {
      state.phoneNumber = action.payload;
    },
    setStep(state, action: PayloadAction<LoginSteps>) {
      state.step = action.payload;
    },
    reset(state) {
      return { ...initialState };
    },
    loginInitStart(state) {
      state.loginStep = {
        loading: true,
        error: null,
      };
    },
    loginInitSuccess(state, action: PayloadAction<LoginInitSuccess>) {
      state.step = 'otp';
      state.operationId = action.payload.operationId;
      state.phoneNumber = action.payload.phoneNumber;
      state.loginStep = {
        loading: false,
        error: null,
      };
      state.resendOtp.lastDate = new Date().getTime();
    },
    loginInitFailure(state, action: PayloadAction<ApiErrorConstruction>) {
      state.loginStep = {
        loading: false,
        error: action.payload.error,
      };
    },
    loginOtpStart(state) {
      state.otpStep = {
        success: false,
        loading: true,
        error: null,
      };
    },
    loginOtpSuccess(state) {
      state.otpStep = {
        success: true,
        loading: false,
        error: null,
      };
    },
    loginOtpFailure(state, action: PayloadAction<ApiErrorConstruction>) {
      state.otpStep = {
        success: false,
        loading: false,
        error: action.payload.error,
        errorCode: action.payload.errorCode,
      };
    },
    loginOtpErrorReset(state) {
      state.otpStep.error = null;
    },
    loginConfirmStart(state) {
      state.confirmStep = {
        success: false,
        loading: true,
        error: null,
      };
    },
    loginConfirmSuccess(state) {
      state.confirmStep = {
        success: true,
        loading: false,
        error: null,
      };
    },
    loginConfirmFailure(state, action: PayloadAction<ApiErrorConstruction>) {
      state.confirmStep = {
        success: false,
        loading: false,
        error: action.payload.error,
      };
    },
    loginConfirmErrorReset(state) {
      state.confirmStep.error = null;
    },
    loginResendOtpStart(state) {
      state.resendOtp = {
        ...state.resendOtp,
        loading: true,
        error: null,
      };
    },
    loginResendOtpSuccess(state) {
      state.resendOtp = {
        loading: false,
        error: null,
        lastDate: new Date().getTime(),
      };
    },
    loginResendOtpFailure(state, action: PayloadAction<ApiErrorConstruction>) {
      state.resendOtp = {
        ...state.resendOtp,
        loading: false,
        error: action.payload.error,
        errorCode: action.payload.errorCode,
      };
    },
    loginResendOtpReset(state) {
      state.resendOtp.error = null;
    },
  },
});

export const {
  setPhoneNumber,
  setStep,
  reset,
  loginInitStart,
  loginInitSuccess,
  loginInitFailure,
  loginOtpStart,
  loginOtpSuccess,
  loginOtpFailure,
  loginOtpErrorReset,
  loginConfirmStart,
  loginConfirmSuccess,
  loginConfirmFailure,
  loginConfirmErrorReset,
  loginResendOtpStart,
  loginResendOtpSuccess,
  loginResendOtpFailure,
  loginResendOtpReset,
} = login.actions;
export default login.reducer;

export const loginInit = (
  username: string,
  password: string
): AppThunk => async dispatch => {
  try {
    dispatch(loginInitStart());
    let newPasswordHash = getPasswordHash(password);
    const { operationId, phone } = await accountLoginInit(username, newPasswordHash);

    dispatch(
      loginInitSuccess({
        phoneNumber: phone,
        operationId,
      })
    );
  } catch (rawError) {
    const error = errorHandle(rawError);
    dispatch(loginInitFailure(error));
  }
};

export const loginOtp = (code: string): AppThunk => async (dispatch, getState) => {
  const {
    login: { operationId },
  } = getState();
  try {
    dispatch(loginOtpStart());

    await confirmOtp(operationId, code);

    dispatch(loginOtpSuccess());
  } catch (rawError) {
    const error = errorHandle(rawError);
    dispatch(loginOtpFailure({...error, errorCode: rawError?.errorCode }));
  }
};

export const loginConfirm = (): AppThunk => async (dispatch, getState) => {
  const {
    login: { operationId },
  } = getState();

  try {
    dispatch(loginConfirmStart());

    const { expiration, token } = await accountLoginConfirm(operationId);

    setAuthToken(token);

    dispatch(loginConfirmSuccess());
  } catch (rawError) {
    const error = errorHandle(rawError);
    dispatch(loginConfirmFailure(error));
  }
};

export const loginResendOtp = (): AppThunk => async (dispatch, getState) => {
  const {
    login: { operationId },
  } = getState();

  try {
    dispatch(loginResendOtpStart());

    await resendOtp(operationId);

    dispatch(loginResendOtpSuccess());
  } catch (rawError) {
    const error = errorHandle(rawError);
    dispatch(loginResendOtpFailure({...error, errorCode: rawError?.errorCode }));
  }
};
