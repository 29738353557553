import { colors } from '@abdt/design-tokens';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    row: {
      textDecoration: 'none',
      height: '56px',
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
);
