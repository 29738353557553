import React from 'react';
import { Typography } from '@abdt/ornament';

import { ReadOnlyField } from 'components/ReadOnlyField';

import { MerchantContracts } from '../../types';

import useStyles from './ContractInfo.style';

interface Props {
  data?: MerchantContracts;
}

const ContractInfo: React.FC<Props> = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Typography variant="body1" bold className={classes.head}>
          Детали счета
        </Typography>
        <ReadOnlyField className={classes.field} title="Номер счета">
          {data?.contractNumber}
        </ReadOnlyField>
        <ReadOnlyField className={classes.field} title="Номер контракта">
          {data?.contractName}
        </ReadOnlyField>
      </div>
    </>
  );
};

export default ContractInfo;
