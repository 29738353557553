import { format, isValid } from 'date-fns';

import { isExists } from 'utils/main';

import { TransactionsFilterForm } from '../../types';

type TransactionsFilterKey = keyof TransactionsFilterForm;

export interface SelectedFilter {
  keys: TransactionsFilterKey[];
  name: string;
}

const filterNames = {
  StartDate: value => 'с ' + value,
  EndDate: value => 'по ' + value,
  MinAmount: value => 'от ' + value,
  MaxAmount: value => 'до ' + value,
  TerminalId: value => 'Терминал: ' + value,
  Status: value => 'Статус: ' + value,
};

export function getSelectedFilters(
  defaultValues: TransactionsFilterForm,
  initialFilter: TransactionsFilterForm,
  valueToName?: Partial<Record<TransactionsFilterKey, string>>
) {
  const result: SelectedFilter[] = [];

  const keys = Object.keys(defaultValues) as TransactionsFilterKey[];
  let i = keys.length;

  const isDeletedKey = (
    currentKey: TransactionsFilterKey,
    targetKey: TransactionsFilterKey
  ) => {
    return (
      currentKey !== targetKey &&
      keys.includes(targetKey) &&
      keys.splice(keys.indexOf(targetKey), 1).length > 0
    );
  };

  while (i-- > 0) {
    const key = keys[i];

    if (['StartDate', 'EndDate'].includes(key)) {
      if (!isValid(defaultValues[key])) {
        continue;
      }

      if (
        isValid(initialFilter[key]) &&
        format(defaultValues[key] as Date, 'yyyy.MM.dd') ===
          format(initialFilter[key] as Date, 'yyyy.MM.dd')
      ) {
        continue;
      }

      let startDate = defaultValues['StartDate'] || initialFilter['StartDate'];
      let endDate = defaultValues['EndDate'] || initialFilter['EndDate'];

      startDate = isValid(startDate) ? startDate : null;
      endDate = isValid(endDate) ? endDate : null;

      if (isDeletedKey(key, 'StartDate')) {
        i--;
      }
      if (isDeletedKey(key, 'EndDate')) {
        i--;
      }

      let name;

      if (startDate && endDate) {
        name = format(startDate, 'yyyy.MM.dd') + ' - ' + format(endDate, 'yyyy.MM.dd');
      } else if (startDate) {
        name = filterNames['StartDate'](format(startDate, 'yyyy.MM.dd'));
      } else if (endDate) {
        name = filterNames['EndDate'](format(endDate, 'yyyy.MM.dd'));
      } else {
        continue;
      }

      result.push({
        keys: ['StartDate', 'EndDate'],
        name,
      });
    } else if (['MinAmount', 'MaxAmount'].includes(key)) {
      if (defaultValues[key] !== initialFilter[key]) {
        const minAmount = defaultValues['MinAmount'];
        const maxAmount = defaultValues['MaxAmount'];

        if (isDeletedKey(key, 'MaxAmount')) {
          i--;
        }
        if (isDeletedKey(key, 'MinAmount')) {
          i--;
        }

        const usedKeys = [];
        const res = [];

        if (minAmount) {
          res.push(filterNames['MinAmount'](minAmount));
          usedKeys.push('MinAmount');
        }

        if (maxAmount) {
          res.push(filterNames['MaxAmount'](maxAmount));
          usedKeys.push('MaxAmount');
        }

        result.push({
          keys: usedKeys,
          name: res.join(' '),
        });
      }
    } else if (defaultValues[key] !== initialFilter[key]) {
      if (filterNames[key]) {
        let name;

        if (valueToName && isExists(valueToName[key])) {
          name = filterNames[key](valueToName[key]);
        } else {
          name = filterNames[key](defaultValues[key]);
        }

        result.push({
          keys: [key],
          name,
        });
      }
    }
  }

  return result;
}
