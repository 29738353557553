import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { TextField, Grid, Button, Modal, CircularProgress } from '@abdt/ornament';

import { DatePickerFormField } from 'components/DatePicker';
import { SelectFormField } from 'components/Select';
import { RootState } from 'store/root-reducer';
import { CreateReportForm, FilterTerminal } from 'features/Reports/types';

const useStyles = makeStyles(theme => ({
  description: {
    marginTop: 8,
  },
  form: {
    width: 432,
    marginTop: 16,
  },
  actions: {
    marginTop: 28,
  },
  error: {
    marginTop: 24,
    color: theme.palette.error.main,
    padding: 10,
    fontSize: 11,
    lineHeight: '13px',
    textShadow: 'none',
    zIndex: 10,
    whiteSpace: 'pre-line',
  },
}));

interface Props {
  onGenerateReport: (formData: CreateReportForm, cb?: Function) => void;
  reportLoading: boolean;
  error: string;
}

const renderStartDateTextField = props => {
  return (
    <TextField
      {...props}
      name="startDate"
      fullWidth={true}
      size="small"
      variant="outlined"
      helperText={props.error ? 'Не может быть больше "Конец периода"' : ''}
    />
  );
};

const renderEndDateTextField = props => {
  return (
    <TextField
      {...props}
      name="endDate"
      fullWidth={true}
      size="small"
      variant="outlined"
      helperText={props.error ? 'Не может быть меньше "Начало периода"' : ''}
    />
  );
};

export const GenerateReport: React.FC<Props> = ({
  onGenerateReport,
  reportLoading,
  error,
}) => {
  const classes = useStyles();
  const [formOpen, setFormOpenOpen] = useState(false);
  const {
    reset,
    handleSubmit,
    errors,
    control,
    watch,
    clearErrors,
  } = useForm<CreateReportForm>({
    defaultValues: {
      startDate: new Date().valueOf(),
      endDate: new Date().valueOf(),
    },
  });

  const { filterTerminals } = useSelector((state: RootState) => state.transactions);

  const terminals: FilterTerminal[] = useMemo(() => {
    if (filterTerminals.loaded && !filterTerminals.error) {
      return filterTerminals.data;
    } else {
      return [];
    }
  }, [filterTerminals]);

  const formProps = {
    errors,
    control,
    clearErrors,
  };

  const formRef = useRef<HTMLFormElement>();

  const resetForm = useCallback(() => {
    reset();
    setFormOpenOpen(false);
  }, []);

  const submit = useCallback(
    formData => {
      onGenerateReport(formData, () => setFormOpenOpen(false));
    },
    [onGenerateReport]
  );

  return (
    <div>
      <Button
        disabled={reportLoading}
        startIcon={reportLoading && <CircularProgress size={20} />}
        onClick={() => setFormOpenOpen(true)}
        variant="contained"
      >
        Сформировать отчет
      </Button>
      <Modal
        open={formOpen}
        onClose={() => setFormOpenOpen(false)}
        transitionTimeout={100}
        disableEnforceFocus
      >
        {formOpen && (
          <form
            ref={formRef}
            data-testid="reports-form"
            className={classes.form}
            onSubmit={handleSubmit(submit)}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <DatePickerFormField
                  formFieldProps={{
                    ...formProps,
                    name: 'startDate',
                    validationRules: {
                      max: {
                        value: watch('endDate'),
                        message: 'Не может быть больше "Конец периода"',
                      },
                    },
                  }}
                  label="Начало периода"
                  maxDate={watch('endDate')}
                  renderInput={renderStartDateTextField}
                />
              </Grid>
              <Grid item>
                <DatePickerFormField
                  formFieldProps={{
                    ...formProps,
                    name: 'endDate',
                    validationRules: {
                      min: {
                        value: watch('startDate'),
                        message: 'Не может быть меньше "Начало периода"',
                      },
                    },
                  }}
                  label="Конец периода"
                  minDate={watch('startDate')}
                  renderInput={renderEndDateTextField}
                />
              </Grid>
              <Grid item>
                <SelectFormField
                  formFieldProps={{
                    ...formProps,
                    validationRules: {
                      required: true,
                    },
                  }}
                  helperText={Boolean(errors['terminalId']) && 'Обязательное поле'}
                  label="Терминал"
                  native={true}
                  name="terminalId"
                >
                  <option aria-label="Не выбрано" value="" />
                  {terminals.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.terminalNumber}
                    </option>
                  ))}
                </SelectFormField>
              </Grid>

              {error && (
                <Grid item>
                  <div className={classes.error}>{error}</div>
                </Grid>
              )}

              <Grid className={classes.actions} container item spacing={1}>
                <Grid item>
                  <Button
                    fullWidth
                    type="submit"
                    disabled={reportLoading}
                    startIcon={reportLoading && <CircularProgress size={20} />}
                  >
                    Сформировать
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={resetForm}
                    disabled={reportLoading}
                  >
                    Отменить
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Modal>
    </div>
  );
};
