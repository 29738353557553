export interface FormDataInfo {
  username: string;
  password: string;
}

export interface LoginInitResponse {
  phone: string;
  operationId: string;
}

export interface LoginConfirmResponse {
  expiration: string;
  token: string;
}

export enum LoginError {
  timeLimit = 13 // Превышено отведенное на подтверждение время
}
