import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchFilterTerminals } from 'features/Transactions/transactionsSlice';
import { useGetReports } from 'features/Reports/hooks/useGetReports';
import { PAGE_SIZE } from 'features/Reports/constants';
import { ReportsQueryParams } from 'features/Reports/types';

import { Reports } from './ReportsList.component';

interface Props {
  page: number;
  queryParams: ReportsQueryParams;
}

export const ReportsListContainer: React.FC<Props> = ({ page, queryParams }) => {
  const { data, error, loading, refetch } = useGetReports();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFilterTerminals());
  }, []);

  useEffect(() => {
    refetch({ page, pageSize: PAGE_SIZE });
  }, [page]);

  return (
    <Reports
      data={data.data}
      queryParams={queryParams}
      pageCount={Math.ceil(data.totalCount / PAGE_SIZE)}
      error={error}
      loading={loading}
      getReports={refetch}
    />
  );
};
