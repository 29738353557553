import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@abdt/ornament';
import { makeStyles } from '@material-ui/core/styles';

import { RootState } from 'store/root-reducer';

const useStyles = makeStyles(() => ({
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
}));

export const ProtectedRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const classes = useStyles();
  const { isAuthorized, loaded } = useSelector((state: RootState) => state.profile);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!loaded) {
          return (
            <div className={classes.loader}>
              <CircularProgress size={40} />
            </div>
          );
        } else if (isAuthorized) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};
