import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  TextField,
  IconButton,
  Typography,
  Button,
  CircularProgress,
} from '@abdt/ornament';
import LoopIcon from '@material-ui/icons/Loop';

import { OtpFormData } from 'types/otp';
import { useRemainTimer } from 'hooks/useRemainTimer';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    color: '#8C909F',
    fontSize: '0.875rem',
  },
  field: {
    marginTop: 20,
  },
  formError: {
    marginTop: 16,
    color: theme.palette.error.main,
  },
  otpDescription: {
    marginTop: 8,
    color: '#8C909F',
    fontSize: '0.75rem',
  },
  action: {
    marginTop: 16,
  },
}));

const OTP_PERIOD_SEC = 60;

interface Props {
  className?: string;
  error: string;
  loading: boolean;
  phoneNumber?: string;
  text: React.ReactNode;
  onSubmit: (formData: OtpFormData) => void;
  sendOtpErrorReset: VoidFunction;

  resendOtpError: string;
  resendOtpLoading: boolean;
  resendOtpLastDate: number;
  onResend: VoidFunction;
  resendOtpErrorReset: VoidFunction;
}

export const Otp: React.FC<Props> = ({
  className,
  error,
  loading,
  phoneNumber,
  text,
  onSubmit,
  sendOtpErrorReset,
  resendOtpError,
  resendOtpLoading,
  resendOtpLastDate,
  onResend,
  resendOtpErrorReset,
}) => {
  const classes = useStyles();

  const { register, handleSubmit, errors, setError } = useForm<OtpFormData>({});
  const remainOtpTimeSec = useRemainTimer(OTP_PERIOD_SEC, resendOtpLastDate);

  useEffect(() => {
    if (error || resendOtpError) {
      let message;

      if (error) {
        message = error;
        sendOtpErrorReset();
      } else if (resendOtpError) {
        message = resendOtpError;
        resendOtpErrorReset();
      }

      setError('code', {
        type: 'manual',
        message,
      });
    }
  }, [error, resendOtpError, setError]);

  const otpResendDisabled = remainOtpTimeSec > 0;

  let errorText;
  if (Boolean(errors.code)) {
    errorText = errors.code.message || 'Неверный код';
  }

  return (
    <>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        {text}
        <TextField
          className={classes.field}
          placeholder="00000"
          type="text"
          inputMode="tel"
          name="code"
          autoComplete="off"
          label="Код из СМС"
          inputProps={{
            maxLength: 5,
          }}
          error={Boolean(errors.code)}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={onResend}
                disabled={resendOtpLoading || otpResendDisabled || loading}
              >
                <LoopIcon htmlColor="#A4A8B8" />
              </IconButton>
            ),
          }}
          helperText={errorText}
          inputRef={register({
            required: true,
            maxLength: 5,
            minLength: 5,
          })}
        />
        <Typography className={classes.otpDescription} variant="body1">
          {otpResendDisabled
            ? `Вы сможете отправить СМС повторно через ${remainOtpTimeSec} сек.`
            : 'Вы можете отправить СМС повторно'}
        </Typography>

        <div className={classes.action}>
          <Button
            startIcon={loading && <CircularProgress size={20} />}
            disabled={loading}
            type="submit"
            fullWidth
            variant="contained"
          >
            Продолжить
          </Button>
        </div>
      </form>
    </>
  );
};
