import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/root-reducer';
import {
  MerchantContracts,
  MerchantTerminal,
  TerminalQueryParams,
} from 'features/Merchant/types';

import { fetchMerchantDetails } from '../../merchantSlice';

import TerminalList from './TerminalList.component';

interface Props {
  contractId: string;
  queryParams: TerminalQueryParams;
}

const TerminalListContainer: React.FC<Props> = ({ contractId }) => {
  const { merchantDetails } = useSelector((state: RootState) => state.merchant);
  const { data } = useSelector((state: RootState) => state.profile);
  const [terminals, setterminals] = useState<MerchantTerminal[]>([]);
  const [contract, setcontract] = useState<MerchantContracts>();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMerchantDetails(data.merchantId));
  }, [data.merchantId]);

  useEffect(() => {
    if (merchantDetails.data && contractId) {
      setcontract(
        merchantDetails.data.contracts.find(_contract => _contract.id === contractId)
      );
      setterminals(contract ? contract.terminals : []);
    }
  }, [merchantDetails, contract, contractId]);

  return (
    <TerminalList
      data={terminals}
      error={merchantDetails.error}
      loading={merchantDetails.loading}
      merchantContract={contract}
    />
  );
};

export default TerminalListContainer;
