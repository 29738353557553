import React, { useCallback, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@abdt/ornament';
import { colors } from '@abdt/design-tokens';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { MerchantTerminal } from 'features/Merchant/types';
import { updateTerminalName } from 'features/Merchant/api';
import {
  handleCancellableApiError,
  useCancellablePromise,
} from 'utils/cancellable-promise';
import { Typography } from 'components/Typography';
import { silentFetchMerchantDetails } from 'features/Merchant/merchantSlice';
import { RootState } from 'store/root-reducer';

import Result from '../Result';
import Form from '../Form';

import useStyles from './DataGrid.style';

interface Props {
  data: MerchantTerminal[];
}

const DataGrid: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { cancellablePromise } = useCancellablePromise();
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [openResult, setOpenResult] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {
    data: { merchantId },
  } = useSelector((state: RootState) => state.profile);

  const [selectedTerminal, setSelectedTerminal] = useState<MerchantTerminal | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleOpenForm = useCallback((item: MerchantTerminal) => {
    setSelectedTerminal(item);
    setOpenForm(true);
  }, []);

  const handleCloseForm = useCallback(() => {
    setOpenForm(false);
    setSelectedTerminal(null);
  }, []);

  const handleCloseResult = useCallback(() => {
    setOpenResult(false);
    setSuccess(false);
    handleCloseForm();
    dispatch(silentFetchMerchantDetails(merchantId));
  }, [merchantId]);

  const handleSubmit = useCallback(
    formData => {
      setLoading(true);

      cancellablePromise(updateTerminalName(selectedTerminal.id, formData))
        .then(result => {
          setSuccess(true);
        })
        .catch(
          handleCancellableApiError(error => {
            setError(error.error);
          })
        )
        .finally(() => {
          setLoading(false);
          setOpenResult(true);
        });
    },
    [selectedTerminal, merchantId]
  );

  return (
    <>
      <div className={classes.root}>
        <Typography variant="body1" bold className={classes.head}>
          Терминалы
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Номер</TableCell>
                <TableCell>Описание</TableCell>
                <TableCell>Наименование</TableCell>
                <TableCell>МСС-код</TableCell>
                <TableCell>Статус</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(item => {
                return (
                  <TableRow
                    className={classes.row}
                    hover
                    selected={selectedTerminal?.id === item.id}
                    key={item.id}
                  >
                    <TableCell>
                      <div>{item.terminalNumber}</div>
                    </TableCell>
                    <TableCell>
                      <div>{item.terminalName}</div>
                    </TableCell>
                    <TableCell>
                      <div>{item.alternateName}</div>
                    </TableCell>
                    <TableCell>
                      <div>{item.mcc}</div>
                    </TableCell>
                    <TableCell>
                      <div>
                        {item.isActive ? (
                          <Chip
                            style={{
                              backgroundColor: colors.grass100,
                              color: theme.palette.getContrastText(colors.grass100),
                            }}
                            label="Активен"
                          />
                        ) : (
                          <Chip
                            style={{
                              backgroundColor: colors.red100,
                              color: theme.palette.getContrastText(colors.red100),
                            }}
                            label="Отключен"
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        size="small"
                        className={classNames(classes.button, 'button')}
                        onClick={() => handleOpenForm(item)}
                        disabled={loading}
                        variant="text"
                      >
                        Изменить
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Form
        open={openForm}
        onClose={handleCloseForm}
        onSubmit={handleSubmit}
        selectedTerminal={selectedTerminal}
        loading={loading}
      />
      <Result
        open={openResult}
        error={error}
        success={success}
        onClose={handleCloseResult}
      />
    </>
  );
};

export default DataGrid;
