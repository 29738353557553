import React from 'react';

import { Transaction } from 'features/Transactions/types';
import { OtpFormData } from 'types/otp';

import {
  ChargebackForm,
  ChargebackConfirm,
  ChargebackSuccess,
  ChargebackFailure,
} from './components';
import { ChargebackFormData, ChargebackStep, Refund } from './types';

interface Props {
  className?: string;
  step: ChargebackStep;
  transactionDetail: Transaction;
  confirmError: string;
  refundType: Refund;

  onSubmit: (formData: ChargebackFormData) => void;
  submitError: string;
  loadingSubmit: boolean;

  onSubmitOtp: (form: OtpFormData) => void;
  submitOtpError: string;
  loadingOtp: boolean;
  onOtpErrorReset: () => void;

  resendOtpError: string;
  resendOtpLoading: boolean;
  resendOtpLastDate: number;
  onResendOtp: () => void;
  resendOtpErrorReset: () => void;

  onCancel: () => void;
  onRepeat: () => void;
}

export const Chargeback: React.FC<Props> = ({
  step,
  onSubmit,
  submitError,
  loadingSubmit,
  onCancel,
  className,
  transactionDetail,
  onSubmitOtp,
  onRepeat,
  confirmError,
  onOtpErrorReset,
  loadingOtp,
  submitOtpError,
  resendOtpError,
  resendOtpLoading,
  resendOtpLastDate,
  onResendOtp,
  resendOtpErrorReset,
  refundType,
}) => {
  if (step === 'form') {
    return (
      <ChargebackForm
        className={className}
        error={submitError}
        loading={loadingSubmit}
        onSubmit={onSubmit}
        onCancel={onCancel}
        transactionDetail={transactionDetail}
        refundType={refundType}
      />
    );
  }

  if (step === 'confirm') {
    return (
      <ChargebackConfirm
        error={submitOtpError}
        loading={loadingOtp}
        onSubmit={onSubmitOtp}
        sendOtpErrorReset={onOtpErrorReset}
        resendOtpError={resendOtpError}
        resendOtpLoading={resendOtpLoading}
        resendOtpLastDate={resendOtpLastDate}
        onResendOtp={onResendOtp}
        resendOtpErrorReset={resendOtpErrorReset}
        refundType={refundType}
      />
    );
  }

  if (step === 'success') {
    return <ChargebackSuccess className={className} onCancel={onCancel} refundType={refundType}/>;
  }

  if (step === 'failure') {
    return (
      <ChargebackFailure
        className={className}
        onCancel={onCancel}
        onRepeat={onRepeat}
        error={confirmError}
        refundType={refundType}
      />
    );
  }

  return null;
};
