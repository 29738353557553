import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import '@abdt/fonts';
import { useDispatch } from 'react-redux';

import { ProtectedRoute } from 'components/ProtectedRoute';
import { fetchProfileInfo } from 'store/features/profile';
import { LoginPage } from 'pages/Login';
import { TransactionsPage, TransactionDetailsPage } from 'pages/Transactions';
import { ReportsPage } from 'pages/Reports';
import { NotFoundPage } from 'pages/NotFound';
import {
  MerchantPage,
  ContractDetailsPage as MerchantContractDetailsPage,
  TerminalDetailsPage as MerchantTerminalDetailsPage,
} from 'pages/Merchant';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProfileInfo());
  }, []);

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/transactions" />
      </Route>
      <Route path="/login">
        <LoginPage />
      </Route>
      <ProtectedRoute path="/transactions/:transactionId">
        <TransactionDetailsPage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/transactions">
        <TransactionsPage />
      </ProtectedRoute>
      <ProtectedRoute path="/reports">
        <ReportsPage />
      </ProtectedRoute>
      <ProtectedRoute path="/merchant/contracts/:contractId/terminals/:terminalId">
        <MerchantTerminalDetailsPage />
      </ProtectedRoute>
      <ProtectedRoute path="/merchant/contracts/:contractId">
        <MerchantContractDetailsPage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/merchant">
        <MerchantPage />
      </ProtectedRoute>
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
}

export default App;
