import React from 'react';
import { Close } from '@abdt/icons';
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  TextField,
} from '@abdt/ornament';
import { useForm } from 'react-hook-form';
import { fontWeight } from '@abdt/design-tokens';

import { MerchantTerminal, ITerminalUpdateNameForm } from 'features/Merchant/types';
import { Typography } from 'components/Typography';

import useStyles from './Form.style';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  loading: boolean;
  selectedTerminal: MerchantTerminal | null;
  onSubmit: (formData) => void;
}

const Form: React.FC<Props> = ({
  open,
  loading,
  selectedTerminal,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm<ITerminalUpdateNameForm>();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper }}
    >
      {open && (
        <div className={classes.terminalDetails}>
          <IconButton onClick={onClose} className={classes.closeIcon} size="small">
            <Close size="large" color="#10002b " />
          </IconButton>
          <Typography
            align="left"
            variant="h5"
            style={{ fontWeight: fontWeight.regular, paddingRight: '40px' }}
          >
            Изменение данных терминала
          </Typography>
          <Box marginTop={4} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <TextField
                  name="name"
                  inputProps={{
                    'data-testid': 'name',
                  }}
                  type="text"
                  label="Наименование"
                  error={Boolean(errors.name)}
                  defaultValue={selectedTerminal.alternateName}
                  inputRef={register({
                    required: true,
                  })}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  startIcon={loading && <CircularProgress size={20} />}
                  fullWidth
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </Drawer>
  );
};

export default Form;
