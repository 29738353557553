import React, { useCallback, useState } from 'react';
import { set, format } from 'date-fns';
import { useSnackbar } from 'notistack';

import {
  handleCancellableApiError,
  useCancellablePromise,
} from 'utils/cancellable-promise';
import { cleanObject, saveAs, toApiDatetime } from 'utils/main';
import { downloadReport } from 'services/api';
import {
  GetTransactionsParams,
  TransactionsFilterForm,
} from 'features/Transactions/types';

import { Report } from './Report.component';

interface Props {
  filterFormData: TransactionsFilterForm;
}

export const ReportContainer: React.FC<Props> = ({ filterFormData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise } = useCancellablePromise();

  const [loading, setLoading] = useState(false);

  const handleReportDownload = useCallback(() => {
    setLoading(true);
    const filterData: Omit<GetTransactionsParams, 'Page' | 'PageSize'> = cleanObject({
      ...filterFormData,
      MinAmount: filterFormData.MinAmount ? +filterFormData.MinAmount : null,
      MaxAmount: filterFormData.MaxAmount ? +filterFormData.MaxAmount : null,
    });

    if (filterFormData.StartDate) {
      filterData.StartDate = toApiDatetime(
        set(filterFormData.StartDate, {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
      );
    }

    if (filterFormData.EndDate) {
      filterData.EndDate = toApiDatetime(
        set(filterFormData.EndDate, {
          hours: 23,
          minutes: 59,
          seconds: 59,
          milliseconds: 999,
        })
      );
    }

    cancellablePromise(
      downloadReport({
        ...filterData,
      })
    )
      .then(({ blob, fileName }) => {
        saveAs(blob, fileName);
      })
      .catch(
        handleCancellableApiError(error => {
          enqueueSnackbar(error.error, {
            variant: 'error',
            autoHideDuration: 3000,
            persist: false,
          });
        })
      )
      .finally(() => {
        setLoading(false);
      });
  }, [filterFormData]);

  return <Report reportLoading={loading} onReportDownload={handleReportDownload} />;
};
