import { useCallback, useEffect, useState } from 'react';

import {
  handleCancellableApiError,
  useCancellablePromise,
} from 'utils/cancellable-promise';
import { PageContent } from 'types/api';

import { GetReportsParams, ReportItem } from '../types';
import { getReports } from '../api';
import { PAGE_SIZE } from '../constants';
interface Props {
  manual: boolean;
  defaultParams: GetReportsParams;
}
const DEFAULT_PROPS: Props = {
  manual: false,
  defaultParams: { page: 1, pageSize: PAGE_SIZE },
};

const DEFAULT_DATA: PageContent<ReportItem> = { data: [], totalCount: 0 }

export const useGetReports = (props: Props = DEFAULT_PROPS) => {
  const { cancellablePromise } = useCancellablePromise();

  const [data, setData] = useState<PageContent<ReportItem>>(DEFAULT_DATA);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!props.manual) {
      setLoading(true);
      cancellablePromise(getReports(props.defaultParams))
        .then(result => {
          setData(result);
        })
        .catch(
          handleCancellableApiError(error => {
            setError(error.error);
          })
        )
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const refetch = useCallback((params: GetReportsParams) => {
    setLoading(true);

    cancellablePromise(getReports(params))
      .then(result => {
        setData(result);
      })
      .catch(
        handleCancellableApiError(error => {
          setError(error.error);
        })
      )
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { data, error, loading, refetch };
};
