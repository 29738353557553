/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo, useCallback } from 'react';
import {
  Breadcrumbs,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@abdt/ornament';
import { Link as RouterLink } from 'react-router-dom';

import { a11yProps, TabPanel } from 'components/TabPanel';
import { PageHead } from 'components/PageHead';
import { Container } from 'components/Container';
import { cleanObject } from 'utils/main';
import { PageHeadTitle } from 'components/PageHeadTitle';

import MerchantInfoDetails from '../MerchantInfoDetails';
import MerchantInfoUsers from '../MerchantInfoUsers';
import ContractList from '../ContractList';
import { MerchantDetail, MerchantInfoQueryParams } from '../../types';

import useStyles from './MerchantInfo.style';

interface Props {
  data: MerchantDetail | undefined;
  error: string;
  loading: boolean;
  returnQueryParams: string;
  queryParams: MerchantInfoQueryParams;
}

const MerchantInfo: React.FC<Props> = ({
  data,
  error,
  loading,
  returnQueryParams,
  queryParams,
}) => {
  const classes = useStyles();

  const getTabLink = useCallback(
    (tab: number) => ({
      pathname: window.location.pathname,
      search: `?${new URLSearchParams(
        cleanObject({
          tab,
        })
      ).toString()}`,
    }),
    []
  );

  const tab = useMemo(() => (queryParams.tab ? +queryParams.tab : 0), [queryParams]);

  const renderContent = useMemo(() => {
    if (loading) {
      return (
        <Container className={classes.container}>
          <div className={classes.loader}>
            <CircularProgress size={40} />
          </div>
        </Container>
      );
    }
    if (error) {
      return (
        <Container className={classes.container}>
          <div className={classes.message}>
            <Typography
              className={classes.messageTitle}
              variant="h4"
              color="error"
              gutterBottom
            >
              Ошибка получения данных
            </Typography>
          </div>
        </Container>
      );
    }
    if (data) {
      return (
        <>
          <div className={classes.tabsContainer}>
            <Container>
              <Tabs
                className={classes.tabs}
                value={tab}
                aria-label="Навигация по деталям организации"
              >
                <Tab
                  label="Основная информация"
                  {...a11yProps(0)}
                  //@ts-ignore
                  component={RouterLink}
                  to={getTabLink(0)}
                />
                <Tab
                  label="Список счетов"
                  {...a11yProps(1)}
                  //@ts-ignore
                  component={RouterLink}
                  to={getTabLink(1)}
                />
                <Tab
                  label="Сотрудники"
                  {...a11yProps(2)}
                  //@ts-ignore
                  component={RouterLink}
                  to={getTabLink(2)}
                />
              </Tabs>
            </Container>
          </div>
          <Container className={classes.container}>
            <TabPanel value={tab} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Paper elevation={0} className={classes.content}>
                    <MerchantInfoDetails data={data.merchant} />
                  </Paper>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Grid item xs={12}>
                <Paper elevation={0} className={classes.content}>
                  <ContractList data={data.contracts} queryParams={queryParams} />
                </Paper>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <Grid item xs={12}>
                <Paper elevation={0} className={classes.content}>
                  <MerchantInfoUsers data={data.users} />
                </Paper>
              </Grid>
            </TabPanel>
          </Container>
        </>
      );
    }
    return null;
  }, [loading, data, error, tab, getTabLink, queryParams]);

  return (
    <>
      <section className={classes.root}>
        <PageHead verticalSpacing={3}>
          <PageHeadTitle> Об организации</PageHeadTitle>
        </PageHead>
        {renderContent}
      </section>
    </>
  );
};

export default MerchantInfo;
