import React from 'react';

import { ReadOnlyField } from 'components/ReadOnlyField';

import { Merchant } from '../../types';

import useStyles from './MerchantInfoDetails.style';

interface Props {
  data: Merchant;
}

const MerchantInfoDetails: React.FC<Props> = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <ReadOnlyField className={classes.field} title="Название">
          {data.name}
        </ReadOnlyField>
        <ReadOnlyField className={classes.field} title="ИНН">
          {data.regNumber}
        </ReadOnlyField>
        <ReadOnlyField className={classes.field} title="Email">
          {data.email}
        </ReadOnlyField>
        <ReadOnlyField className={classes.field} title="Телефон">
          {data.phoneNumber}
        </ReadOnlyField>
      </div>
    </>
  );
};

export default MerchantInfoDetails;
