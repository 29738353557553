import React, { useState, memo, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { AkbarsLogo } from '@abdt/icons';
import { Button } from '@abdt/ornament';

import { Container } from 'components/Container';
import { RootState } from 'store/root-reducer';
import { logout } from 'store/features/profile';

const useStyles = makeStyles(() => ({
  root: {
    background: '#fff',
    borderBottom: '1px solid #e1e1e1',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  abbLogo: {
    height: 20,
    width: 101,
  },
  nav: {
    marginLeft: 65,
  },
  navLink: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: '#6c6c6c',
    fontSize: 12,

    '& + &': {
      marginLeft: 32,
    },
  },
  activeNavLink: {
    color: '#10002b',
  },
  actions: {
    marginLeft: 'auto',
  },
}));

interface Props {}

export const Header: React.FC<Props> = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isAuthorized } = useSelector((state: RootState) => state.profile);
  const handleLogout = () => dispatch(logout());

  return (
    <header className={classNames(classes.root)}>
      <Container className={classes.wrapper}>
        <div className={classes.abbLogo}>
          <AkbarsLogo />
        </div>
        <nav className={classes.nav}>
          <NavLink
            to="/transactions"
            className={classes.navLink}
            activeClassName={classes.activeNavLink}
          >
            Транзакции
          </NavLink>
          <NavLink
            to="/reports"
            className={classes.navLink}
            activeClassName={classes.activeNavLink}
          >
            Отчеты
          </NavLink>
          <NavLink
            to="/merchant"
            className={classes.navLink}
            activeClassName={classes.activeNavLink}
          >
            Об организации
          </NavLink>
        </nav>
        <div className={classes.actions}>
          {isAuthorized && (
            <Button variant="text" onClick={handleLogout}>
              Выйти
            </Button>
          )}
        </div>
      </Container>
    </header>
  );
});
