import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@abdt/ornament';

import { MerchantUser } from '../../types';

import useStyles from './MerchantInfoUsers.style';

interface Props {
  data: MerchantUser[];
}

const MerchantInfoUsers: React.FC<Props> = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Сотрудник</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Телефон</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(item => {
                return (
                  <TableRow className={classes.row} key={item.email}>
                    <TableCell>
                      <div>{item.normalizedUserName}</div>
                    </TableCell>
                    <TableCell>
                      <div>{item.email}</div>
                    </TableCell>
                    <TableCell>
                      <div>{item.phoneNumber}</div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default MerchantInfoUsers;
