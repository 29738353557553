import { ApiBlob, ApiErrorConstruction, ApiResponse } from 'types/api';

function apiError(options: ApiErrorConstruction): ApiErrorConstruction {
  return options;
}

async function handlePlainResponse(response: Response) {
  if (!response.ok) {
    throw apiError({
      error: 'Внутренняя ошибка',
    });
  }

  return await response.text();
}

async function handleBlobResponse(response: Response) {
  if (!response.ok) {
    throw apiError({
      error: 'Внутренняя ошибка',
    });
  }
  const fileName = response.headers
    .get('content-disposition')
    .split(';')
    .find(n => n.includes('filename*=UTF-8'))
    .replace('filename*=UTF-8', '')
    .replace(/["']/g, '')
    .trim();

  const blob = await response.blob();
  return { blob, fileName };
}

async function handleJsonResponse<T>(response: Response): Promise<ApiResponse<T>> {
  const result: ApiResponse<T> = await response.json();

  if (result.error) {
    throw apiError({
      error: result.error,
      errorCode: result.errorCode,
    });
  }

  return result;
}

function handleRequestError(result) {
  throw apiError({
    error: result.error,
    errorCode: result.errorCode,
  });
}

export function jsonResponseHandler<T>(
  request: () => Promise<Response>
): Promise<ApiResponse<T>> {
  return new Promise((resolve, reject) => {
    request()
      .then(response => resolve(handleJsonResponse<T>(response)))
      .catch(error => reject(handleRequestError(error)));
  });
}

export function plainResponseHandler(request: () => Promise<Response>): Promise<string> {
  return new Promise((resolve, reject) => {
    request()
      .then(response => resolve(handlePlainResponse(response)))
      .catch(error => reject(handleRequestError(error)));
  });
}

export function blobResponseHandler(request: () => Promise<Response>): Promise<ApiBlob> {
  return new Promise((resolve, reject) => {
    request()
      .then(response => resolve(handleBlobResponse(response)))
      .catch(error => reject(handleRequestError(error)));
  });
}
