import React from 'react';

import { HomeLayout } from 'layouts/Home';
import { NotFound } from 'features/NotFound';

interface Props {}

export const NotFoundPage: React.FC<Props> = () => {
  return (
    <HomeLayout>
      <NotFound />
    </HomeLayout>
  );
};
