import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Modal, Chip, Typography, IconButton } from '@abdt/ornament';
import { format } from 'date-fns';
import { Comments } from '@abdt/icons';

import { ReadOnlyField } from 'components/ReadOnlyField';
import { safeArrayCheck } from 'utils/main';

import { TransactionDetailsLogsContainer } from '../TransactionDetailsLogs';
import { Transaction } from '../../types';
import { CurrencyList } from '../../currency';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  head: {
    marginBottom: 24,
  },
  field: {
    '& + &': {
      marginTop: 16,
    },
  },
  detailsLogs: {
    width: '70vw',
  },
}));

interface Props {
  data: Transaction;
}

export const TransactionDetailsInfo: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [openLogs, setOpenLogs] = useState(false);

  return (
    <>
      <div className={classes.root}>
        <Typography variant="body1" bold className={classes.head}>
          Детальная информация{' '}
          <IconButton onClick={() => setOpenLogs(true)}>
            <Comments />
          </IconButton>
        </Typography>
        <div>
          <ReadOnlyField className={classes.field} title="Дата и время">
            {format(new Date(data.transactionDate), 'dd.MM.yyyy HH:mm')}
          </ReadOnlyField>
          <ReadOnlyField className={classes.field} title="Номер заказа">
            {data.orderId}
          </ReadOnlyField>
          <ReadOnlyField className={classes.field} title="RRN">
            {data.refNumber}
          </ReadOnlyField>
          <ReadOnlyField className={classes.field} title="Код авторизации">
            {data.authCode}
          </ReadOnlyField>
          <ReadOnlyField className={classes.field} title="Сумма">
            {CurrencyList.includes(data.currency)
              ? data.amount.toLocaleString('ru-RU', {
                  style: 'currency',
                  currency: data.currency,
                })
              : data.amount.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                })}
          </ReadOnlyField>
          <ReadOnlyField className={classes.field} title="Комиссия">
            {CurrencyList.includes(data.currency)
              ? data.fee?.toLocaleString('ru-RU', {
                  style: 'currency',
                  currency: data.currency,
                })
              : data.fee?.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                })}
          </ReadOnlyField>
          <ReadOnlyField className={classes.field} title="Код ответа">
            {data.returnCode}
          </ReadOnlyField>
          <ReadOnlyField className={classes.field} title="Терминал">
            {data.terminalWay4} ({data.shopName})
          </ReadOnlyField>
          <ReadOnlyField className={classes.field} title="Карта">
            {data.card} ({data.paymentSystem})
          </ReadOnlyField>
          <ReadOnlyField className={classes.field} title="Статус">
            <Chip
              style={{
                backgroundColor: data.status.color,
                color: theme.palette.getContrastText(data.status.color),
              }}
              label={data.status.name}
            />
          </ReadOnlyField>
          {safeArrayCheck(data.partnerFields) &&
            data.partnerFields.map(({ value, id, displayName }) => (
              <ReadOnlyField className={classes.field} title={displayName} key={id}>
                {value}
              </ReadOnlyField>
            ))}
        </div>
      </div>

      <Modal open={openLogs} onClose={() => setOpenLogs(false)} transitionTimeout={100}>
        {openLogs && (
          <TransactionDetailsLogsContainer
            className={classes.detailsLogs}
            transactionId={data.id}
          />
        )}
      </Modal>
    </>
  );
};
