import React, { useCallback, useEffect, useState } from 'react';

import { Transaction } from 'features/Transactions/types';
import {
  handleCancellableApiError,
  useCancellablePromise,
} from 'utils/cancellable-promise';
import { confirmOtp, refundConfirm, refundInit, resendOtp } from 'services/api';
import { OtpFormData } from 'types/otp';

import { ChargebackFormData, ChargebackStep, Refund } from './types';
import { Chargeback } from './Chargeback.component';
import { useDispatch } from 'react-redux';
import { repeatFetchTransactionDetails } from 'features/Transactions/transactionsSlice';

interface Props {
  className?: string;
  onCancel: () => void;
  transactionDetail: Transaction;
}

export const ChargebackContainer: React.FC<Props> = ({
  className,
  onCancel,
  transactionDetail,
}) => {
  const [step, setstep] = useState<ChargebackStep>('form');
  const [operationToken, setOperationToken] = useState<string>();
  const [refundType, setRefundType] = useState<Refund>();

  const [formData, setFormData] = useState<ChargebackFormData>();
  const [submitError, setSubmitError] = useState<string>();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [submitOtpError, setSubmitOtpError] = useState<string>();
  const [loadingOtp, setLoadingOtp] = useState(false);

  const [resendOtpLoading, setResendOtpLoading] = useState(false);
  const [resendOtpError, setResendOtpError] = useState<string>();
  const [resendOtpLastDate, setResendOtpLastDate] = useState<number>();

  const [confirmError, setConfirmError] = useState<string>();

  const { cancellablePromise } = useCancellablePromise();

  const dispatch = useDispatch()

  const handleSubmit = useCallback((form: ChargebackFormData) => {
    setSubmitError(null);
    setFormData(form);
    setLoadingSubmit(true);

    cancellablePromise(refundInit(transactionDetail.id, +form.amount))
      .then(result => {
        setOperationToken(result.operationToken);
        setSubmitOtpError(null);
        setLoadingOtp(false);
        setstep('confirm');
        setResendOtpLastDate(new Date().getTime());
      })
      .catch(
        handleCancellableApiError(error => {
          setSubmitError(error.error);
        })
      )
      .finally(() => {
        setLoadingSubmit(false);
      });
  }, []);

  const handleSubmitOtp = useCallback((form: OtpFormData) => {
    setSubmitOtpError(null);
    setLoadingOtp(true);

    cancellablePromise(confirmOtp(operationToken, form.code))
      .then(result => {
        return cancellablePromise(
          refundConfirm(transactionDetail.id, operationToken, +formData.amount)
        )
          .then((response) => {
            if (response.success === true) {
              setstep('success');
              //TODO: Использовать signalR
              dispatch(repeatFetchTransactionDetails(transactionDetail.id))
            }
            if (response.success === false) {
              setstep('failure');
            }
          })
          .catch(
            handleCancellableApiError(error => {
              setstep('failure');
              setConfirmError(error.error);
            })
          )
          .finally(() => {
          })
      })
      .catch(
        handleCancellableApiError(error => {
          setSubmitOtpError(error.error);
        })
      )
      .finally(() => {
        setLoadingOtp(false);
      });
  }, [operationToken, formData]);

  const handleOtpErrorReset = useCallback(() => {
    setSubmitOtpError(null);
  }, []);

  const handleResendOtp = useCallback(() => {
    setResendOtpError(null);
    setResendOtpLoading(true);

    cancellablePromise(resendOtp(operationToken))
      .then(result => {
        setResendOtpLastDate(new Date().getTime());
      })
      .catch(
        handleCancellableApiError(error => {
          setResendOtpError(error.error);
        })
      )
      .finally(() => {
        setResendOtpLoading(false);
      });
  }, [operationToken]);

  const handleResendOtpErrorReset = useCallback(() => {
    setResendOtpError(null);
  }, []);

  const handleRepeat = useCallback(() => {
    setLoadingSubmit(false);
    setSubmitError(null);
    setstep('form');
  }, []);

  useEffect(() => {
    if (transactionDetail.isClirinc) {
      setRefundType(Refund.refund)
    } else {
      setRefundType(Refund.cancel)
    }
  }, [])

  return (
    <Chargeback
      className={className}
      step={step}
      transactionDetail={transactionDetail}
      confirmError={confirmError}
      onSubmit={handleSubmit}
      submitError={submitError}
      loadingSubmit={loadingSubmit}
      onSubmitOtp={handleSubmitOtp}
      submitOtpError={submitOtpError}
      loadingOtp={loadingOtp}
      onOtpErrorReset={handleOtpErrorReset}
      onResendOtp={handleResendOtp}
      resendOtpError={resendOtpError}
      resendOtpErrorReset={handleResendOtpErrorReset}
      resendOtpLastDate={resendOtpLastDate}
      resendOtpLoading={resendOtpLoading}
      onCancel={onCancel}
      onRepeat={handleRepeat}
      refundType={refundType}
    />
  );
};
