import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack'

import { fetchProfileInfo } from 'store/features/profile';
import { RootState } from 'store/root-reducer';
import { OtpFormData } from 'types/otp';
import { getPasswordHash } from 'utils/main';

import {
  loginConfirm,
  loginInit,
  loginOtp,
  loginOtpErrorReset,
  loginResendOtp,
  loginResendOtpReset,
  reset as resetLogin,
} from './loginSlice';
import { Login } from './Login.component';
import { FormDataInfo, LoginError } from './types';

declare global {
  interface Window {
    getPasswordHash: any;
  }
}

export const LoginContainer: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { isAuthorized, loaded, loading: profileLoading } = useSelector(
    (state: RootState) => state.profile
  );
  const { step, phoneNumber, loginStep, otpStep, confirmStep, resendOtp } = useSelector(
    (state: RootState) => state.login
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (otpStep.success) {
      dispatch(loginConfirm());
    }
  }, [otpStep.success]);
  useEffect(() => {
    if (confirmStep.success) {
      dispatch(fetchProfileInfo());
    }
  }, [confirmStep.success]);
  useEffect(() => {
    if (isAuthorized && loaded) {
      const { from } = (location.state as any) || { from: { pathname: '/' } };
      history.replace(from);
    }
  }, [isAuthorized, loaded]);

  useEffect(() => {
    if (otpStep.errorCode === LoginError.timeLimit || resendOtp.errorCode === LoginError.timeLimit) {
      enqueueSnackbar(otpStep.error || resendOtp.error || 'Превышено отведенное на подтверждение время', { variant: 'error' })
      dispatch(resetLogin())
    }
  }, [otpStep, resendOtp])

  const handleLoginPhone = useCallback(
    (formData: FormDataInfo) => {
      dispatch(loginInit(formData.username, formData.password));
    },
    [dispatch]
  );

  const handleLoginOtp = useCallback(
    (formData: OtpFormData) => {
      dispatch(loginOtp(formData.code));
    },
    [dispatch]
  );

  const handleResendOtp = useCallback(() => {
    dispatch(loginResendOtp());
  }, []);

  const handleResendOtpErrorReset = useCallback(() => {
    dispatch(loginResendOtpReset());
  }, []);

  const handleSendOtpErrorReset = useCallback(() => {
    dispatch(loginOtpErrorReset());
  }, []);

  useEffect(() => {
    window.getPasswordHash = getPasswordHash;
    return () => {
      window.getPasswordHash = undefined;
      dispatch(resetLogin());
    };
  }, []);

  return (
    <Login
      step={step}
      phoneNumber={phoneNumber}
      loginStepError={loginStep.error}
      loginStepLoading={loginStep.loading}
      onLogin={handleLoginPhone}
      sendOtpErrorReset={handleSendOtpErrorReset}
      otpStepError={otpStep.error}
      otpStepLoading={otpStep.loading}
      onLoginOtp={handleLoginOtp}
      resendOtpError={resendOtp.error}
      resendOtpLoading={resendOtp.loading}
      resendOtpLastDate={resendOtp.lastDate}
      onResendOtp={handleResendOtp}
      resendOtpErrorReset={handleResendOtpErrorReset}
      profileLoading={profileLoading}
    />
  );
};
