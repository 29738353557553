import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Paper, Typography } from '@abdt/ornament';

import { PageHead } from 'components/PageHead';
import { PageHeadTitle } from 'components/PageHeadTitle';
import { Container } from 'components/Container';
import { GetReportsParams, ReportItem, ReportsQueryParams } from 'features/Reports/types';

import { DataGrid } from '../DataGrid';
import { GenerateReportContainer } from '../GenerateReport';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
  },
  dataGrid: {
    position: 'relative',
    backgroundColor: '#fff',
    borderRadius: 4,
    margin: '24px 0 32px',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  messageTitle: {
    fontSize: 18,
  },
  messageDescription: {
    fontSize: 16,
  },
  pageHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

interface Props {
  data: ReportItem[];
  error: string;
  loading: boolean;
  getReports: (params: GetReportsParams) => void;
  queryParams: ReportsQueryParams;
  pageCount: number;
}

export const Reports: React.FC<Props> = ({
  data,
  error,
  loading,
  getReports,
  queryParams,
  pageCount,
}) => {
  const classes = useStyles();

  const renderContent = useMemo(() => {
    if (loading) {
      return (
        <div className={classes.loader}>
          <CircularProgress data-testid="loader" size={40} />
        </div>
      );
    } else if (error) {
      return (
        <div className={classes.message}>
          <Typography className={classes.messageTitle} variant="h4" color="error">
            Ошибка получения данных
          </Typography>
        </div>
      );
    } else if (data.length === 0) {
      return (
        <div className={classes.message}>
          <Typography className={classes.messageTitle} variant="h4" gutterBottom>
            Отчеты не найдены
          </Typography>
          <Typography className={classes.messageDescription} variant="body1">
            Попробуйте сформировать отчет
          </Typography>
        </div>
      );
    } else {
      return (
        <Container>
          <Paper
            data-testid="reports-container"
            className={classes.dataGrid}
            elevation={0}
          >
            <DataGrid data={data} pageCount={pageCount} queryParams={queryParams} />
          </Paper>
        </Container>
      );
    }
  }, [loading, data, error]);

  return (
    <section className={classes.root}>
      <PageHead>
        <div className={classes.pageHead}>
          <PageHeadTitle>Отчеты</PageHeadTitle>
          <GenerateReportContainer getReports={getReports} queryParams={queryParams} />
        </div>
      </PageHead>
      <div className={classes.content}>{renderContent}</div>
    </section>
  );
};
