import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getAccountInfo } from 'services/api';
import { deleteAuthToken } from 'services/token';
import { ApiErrorConstruction } from 'types/api';
import { errorHandle } from 'utils/main';

import { AppThunk } from '../';

export interface ProfileInfo {
  email: string;
  id: string;
  merchantId: string;
  userName: string;
}

interface ProfileState {
  loading: boolean;
  error: string;
  isAuthorized: boolean;
  loaded: boolean;
  data: ProfileInfo;
}

export const initialState: ProfileState = {
  loading: false,
  error: null,
  isAuthorized: false,
  loaded: false,
  data: null,
};

const profile = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetError(state) {
      state.error = null;
    },
    // logout(state) {
    //   return {
    //     ...initialState,
    //     isLoaded: true,
    //   };
    // },
    getProfileInfoStart(state) {
      state.loaded = false;
      state.loading = true;
      state.error = null;
    },
    getProfileInfoSuccess(state, action: PayloadAction<ProfileInfo>) {
      state.loading = false;
      state.error = null;
      state.loaded = true;
      state.isAuthorized = true;
      state.data = action.payload;
    },
    getProfileInfoFailure(state, action: PayloadAction<ApiErrorConstruction>) {
      state.loading = false;
      state.error = action.payload.error;
      state.data = null;
      state.loaded = true;
      state.isAuthorized = false;
    },
  },
});

export const {
  resetError,
  // logout,
  getProfileInfoStart,
  getProfileInfoSuccess,
  getProfileInfoFailure,
} = profile.actions;
export default profile.reducer;

export const fetchProfileInfo = (): AppThunk => async dispatch => {
  try {
    dispatch(getProfileInfoStart());

    const info = await getAccountInfo();

    dispatch(getProfileInfoSuccess(info));
  } catch (rawError) {
    const error = errorHandle(rawError);
    dispatch(getProfileInfoFailure(error));
  }
};

export const logout = (): AppThunk => async dispatch => {
  try {
    deleteAuthToken();
    window.location.href = '/';
  } catch (rawError) {}
};
