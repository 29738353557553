import React from 'react';
import { useLocation } from 'react-router-dom';

import { HomeLayout } from 'layouts/Home';
import { ReportsListContainer } from 'features/Reports/components/ReportsList';
import { decodeSearchParams } from 'utils/main';
import { ReportsQueryParams } from 'features/Reports/types';

interface Props {}

export const ReportsPage: React.FC<Props> = () => {
  const { search } = useLocation();
  const queryParams = decodeSearchParams<ReportsQueryParams>(search);
  const { page } = queryParams;

  return (
    <HomeLayout>
      <ReportsListContainer page={page ? +page : 1} queryParams={queryParams} />
    </HomeLayout>
  );
};
