import React, { useEffect, useState } from 'react';

import { getTransactionDetailsLogs } from 'services/api';
import {
  handleCancellableApiError,
  useCancellablePromise,
} from 'utils/cancellable-promise';

import { TransactionDetailsLog } from '../../types';

import { TransactionDetailsLogs } from './TransactionDetailsLogs.component';

interface Props {
  className?: string;
  transactionId: string;
  onClose?: () => void;
}

export const TransactionDetailsLogsContainer: React.FC<Props> = ({
  className,
  transactionId,
  onClose,
}) => {
  const { cancellablePromise } = useCancellablePromise();

  const [data, setData] = useState<TransactionDetailsLog[]>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    cancellablePromise(getTransactionDetailsLogs(transactionId))
      .then(result => {
        setData(result);
      })
      .catch(
        handleCancellableApiError(error => {
          setError(error.error);
        })
      )
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <TransactionDetailsLogs
      data={data}
      error={error}
      loading={loading}
      className={className}
    />
  );
};
