import { configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { useDispatch } from 'react-redux';

import rootReducer, { RootState } from './root-reducer';

const store = configureStore({
  reducer: rootReducer,
});

if (process.env.NODE_ENV === 'development' && (module as any).hot) {
  (module as any).hot.accept('./root-reducer', () => {
    const newRootReducer = require('./root-reducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export function useAppDispatch(): ThunkDispatch<
  ReturnType<typeof store.getState>,
  void,
  Action
> {
  return useDispatch();
}

export type AppThunk<T = any> = ThunkAction<Promise<T>, RootState, null, Action<string>>;

export default store;
