import { LoginConfirmResponse, LoginInitResponse } from 'features/Login/types';
import {
  FilterStatus,
  FilterTerminal,
  GetTransactionsParams,
  RefundInitResponse,
  ReportsParams,
  Transaction,
  TransactionDetailsLog,
} from 'features/Transactions/types';
import { PageContent } from 'types/api';
import { ProfileInfo } from 'store/features/profile';

import { apiDelete, apiGet, apiPost, apiPut } from './ajax';
import { blobResponseHandler, jsonResponseHandler } from './request';

export function getTransactions(params: GetTransactionsParams) {
  return jsonResponseHandler<PageContent<Transaction>>(() =>
    apiGet({
      baseUrl: '/api',
      urn: `/Transaction`,
      queryParams: params,
      public: false,
      jsonRequest: true,
    })
  ).then(json => json.result);
}

export function getTransactionDetails(transactionId: string) {
  return jsonResponseHandler<Transaction>(() =>
    apiGet({
      baseUrl: '/api',
      urn: `/Transaction/${transactionId}`,
      public: false,
      jsonRequest: true,
    })
  ).then(json => json.result);
}

export function getTransactionDetailsLogs(transactionId: string) {
  return jsonResponseHandler<TransactionDetailsLog[]>(() =>
    apiGet({
      baseUrl: '/api',
      urn: `/Transaction/logs/${transactionId}`,
      public: false,
      jsonRequest: true,
    })
  ).then(json => (json as any) as TransactionDetailsLog[]);
}

export function getTerminals() {
  return jsonResponseHandler<PageContent<FilterTerminal>>(() =>
    apiGet({
      baseUrl: '/api',
      urn: `/Terminal`,
      public: false,
      jsonRequest: true,
    })
  ).then(json => json.result);
}

export function getFilterStatuses() {
  return jsonResponseHandler<FilterStatus[]>(() =>
    apiGet({
      baseUrl: '/api',
      urn: `/Filter/statuses`,
      public: false,
      jsonRequest: true,
    })
  ).then(json => json.result);
}

export function getFilterPaymentSystems() {
  return jsonResponseHandler<string[]>(() =>
    apiGet({
      baseUrl: '/api',
      urn: `/Filter/PaymentSystems`,
      public: false,
      jsonRequest: true,
    })
  ).then(json => json.result);
}

export function getAccountInfo() {
  return jsonResponseHandler<ProfileInfo>(() =>
    apiGet({
      baseUrl: '/api',
      urn: `/Auth/self`,
      public: false,
      jsonRequest: true,
    })
  ).then(json => json.result);
}

export function accountLoginInit(username: string, password: string) {
  return jsonResponseHandler<LoginInitResponse>(() =>
    apiPost({
      baseUrl: '/api',
      urn: `/Auth/login`,
      body: {
        username,
        password,
      },
      public: true,
      jsonRequest: true,
    })
  ).then(json => json.result);
}

export function accountLoginConfirm(operationId: string) {
  return jsonResponseHandler<LoginConfirmResponse>(() =>
    apiPost({
      baseUrl: '/api',
      urn: '/Auth/token',
      body: { operationId },
      public: true,
      jsonRequest: true,
    })
  ).then(json => json.result);
}

export function confirmOtp(operationToken: string, code: string) {
  return jsonResponseHandler(() =>
    apiPost({
      baseUrl: '/api',
      urn: `/Otp/confirm`,
      body: { operationToken, code },
      public: true,
      jsonRequest: true,
    })
  ).then(json => json.result);
}

export function resendOtp(operationToken: string) {
  return jsonResponseHandler(() =>
    apiPost({
      baseUrl: '/api',
      urn: `/Otp/resend`,
      body: { operationToken },
      public: true,
      jsonRequest: true,
    })
  ).then(json => json.result);
}

export function refundInit(transactionId: string, amount: number) {
  return jsonResponseHandler<RefundInitResponse>(() =>
    apiPost({
      baseUrl: '/api',
      urn: `/Transaction/refund/${transactionId}/create`,
      body: { amount },
      public: false,
      jsonRequest: true,
    })
  ).then(json => json.result);
}

export function refundConfirm(
  transactionId: string,
  operationToken: string,
  amount: number
) {
  return jsonResponseHandler(() =>
    apiPost({
      baseUrl: '/api',
      urn: `/Transaction/${transactionId}/refund`,
      body: { operationToken, amount },
      public: false,
      jsonRequest: true,
    })
  ).then(json => json);
}

export function downloadReport(params: ReportsParams) {
  return blobResponseHandler(() =>
    apiGet({
      baseUrl: '/api',
      urn: `/Report/fields`,
      queryParams: params,
      public: false,
      jsonRequest: true,
    })
  ).then(result => result);
}
