import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { List } from '@abdt/icons';
import { colors } from '@abdt/design-tokens';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
  },
  description: {
    marginTop: 4,
    fontSize: 13,
  },
  tooltip: {
    backgroundColor: colors.neonGray850,
    boxShadow: 'none',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    padding: '8px 12px',
  },
  tooltipArrow: {
    color: colors.neonGray850,
  },
  listIcon: {
    display: 'flex',
    alignItems: 'center',

    '&:hover svg': {
      color: '#00B2A9',
    },
  },
}));

interface Props {
  returnCode: number;
  description: string;
}

export const BadTransactionTooltip: React.FC<Props> = ({ returnCode, description }) => {
  const classes = useStyles();

  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.tooltipArrow,
      }}
      title={
        <>
          <div className={classes.title}>Код ответа: {returnCode}</div>
          <div className={classes.description}>{description}</div>
        </>
      }
      arrow
      placement="left"
    >
      <div className={classes.listIcon}>
        <List width={18} height={18} color="#10002b " />
      </div>
    </Tooltip>
  );
};
