import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Paper, Typography } from '@abdt/ornament';

import { Otp } from 'components/Otp';
import { OtpFormData } from 'types/otp';

import { LoginSteps } from './loginSlice';
import { FormDataInfo } from './types';
import { LoginStep } from './LoginStep';

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    color: '#8C909F',
    fontSize: '0.875rem',
  },
  root: {
    boxSizing: 'border-box',
    width: 380,
    padding: 32,
  },
}));

interface Props {
  step: LoginSteps;
  phoneNumber: string;

  loginStepError: string;
  loginStepLoading: boolean;
  onLogin: (formData: FormDataInfo) => void;

  otpStepError: string;
  otpStepLoading: boolean;
  onLoginOtp: (formData: OtpFormData) => void;
  sendOtpErrorReset: VoidFunction;

  resendOtpError: string;
  resendOtpLoading: boolean;
  resendOtpLastDate: number;
  onResendOtp: () => void;
  resendOtpErrorReset: VoidFunction;

  profileLoading: boolean;
}

export const Login: React.FC<Props> = ({
  step,
  phoneNumber,
  loginStepError,
  loginStepLoading,
  onLogin,
  otpStepError,
  otpStepLoading,
  onLoginOtp,
  sendOtpErrorReset,
  resendOtpError,
  resendOtpLoading,
  resendOtpLastDate,
  onResendOtp,
  resendOtpErrorReset,
  profileLoading,
}) => {
  let view;

  const classes = useStyles();

  if (step === 'otp') {
    view = (
      <Otp
        phoneNumber={phoneNumber}
        error={otpStepError}
        loading={otpStepLoading}
        onSubmit={onLoginOtp}
        sendOtpErrorReset={sendOtpErrorReset}
        resendOtpError={resendOtpError}
        resendOtpLoading={resendOtpLoading}
        resendOtpLastDate={resendOtpLastDate}
        onResend={onResendOtp}
        resendOtpErrorReset={resendOtpErrorReset}
        text={
          <>
            <Typography className={classes.description} variant="body1">
              Для подтверждения номера телефона и входа в профиль мы отправили СМС-код на
              номер
            </Typography>
            <Typography className={classes.description} variant="body1">
              +7 (***) ***-{phoneNumber.slice(-4)}
            </Typography>
          </>
        }
      />
    );
  } else {
    view = (
      <LoginStep
        onSubmit={onLogin}
        loading={loginStepLoading || profileLoading}
        error={loginStepError}
      />
    );
  }

  return <Paper className={classes.root}>{view}</Paper>;
};
