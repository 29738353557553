import React from 'react';
import cx from 'classnames';
import {
  TypographyProps,
  TypographyVariant,
  TypographyVariantMapping,
} from '@abdt/ornament';
import { makeStyles, Typography as MUITypography } from '@material-ui/core';

// TODO: Затащить в @abdt/ornament Typography заголовок h5
type TypographyVariantExtended = 'h5' | TypographyVariant;

type TypographyVariantMappingExtended = 'h5' | TypographyVariantMapping;

interface TypographyPropsExtended extends Omit<TypographyProps, 'variant' | 'component'> {
  variant: TypographyVariantExtended;
  component?: TypographyVariantMappingExtended;
}

const styles = theme => ({
  boldHeading: {
    fontWeight: theme.typography.fontWeightBold,
  },
  boldText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  underline: {
    textDecoration: 'underline',
  },
});

const useStyles = makeStyles(styles);

/** Типографический элемент */
export const Typography: React.FC<TypographyPropsExtended> = props => {
  const { bold, underline, variant, ...other } = props;

  // Разделяем заголовки от обычного текста
  const isHeading = ['h1', 'h2', 'h3', 'h4', 'h5'].some(el => el === variant);

  const s = useStyles();

  return (
    <MUITypography
      {...other}
      variant={variant}
      classes={{
        root: cx({
          [s.boldHeading]: bold && isHeading,
          [s.boldText]: bold && !isHeading,
          [s.underline]: underline && !isHeading,
        }),
      }}
    />
  );
};

Typography.defaultProps = {
  color: 'primary',
  bold: false,
  underline: false,
};
