import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@abdt/ornament';
import { Refund } from '../types';

const useStyles = makeStyles(() => ({
  description: {
    marginTop: 8,
  },
  actions: {
    marginTop: 28,
  },
}));

const titleByRefundType = {
  [Refund.refund]: 'Не удалось выполнить возврат',
  [Refund.cancel]: 'Не удалось выполнить отмену'
}

interface Props {
  className?: string;
  error?: string;
  onCancel: () => void;
  onRepeat: () => void;
  refundType: Refund;
}

export const ChargebackFailure: React.FC<Props> = ({
  className,
  onCancel,
  onRepeat,
  error,
  refundType,
}) => {
  const classes = useStyles();

  return (
    <div className={className}>
      <Typography variant="h4">{titleByRefundType[refundType]}</Typography>
      <Typography variant="body2" className={classes.description}>
        {error}
      </Typography>
      <Grid className={classes.actions} container item spacing={1}>
        <Grid item>
          <Button fullWidth onClick={onRepeat}>
            Повторить
          </Button>
        </Grid>
        <Grid item>
          <Button fullWidth variant="outlined" onClick={onCancel}>
            Отменить
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
