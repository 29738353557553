import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { set } from 'date-fns';
import { useHistory } from 'react-router-dom';

import {
  handleCancellableApiError,
  useCancellablePromise,
} from 'utils/cancellable-promise';
import { getTransactions } from 'services/api';
import { cleanObject, toApiDatetime, toSearchParams, tryParseDate } from 'utils/main';
import {
  fetchFilterPaymentSystems,
  fetchFilterStatuses,
  fetchFilterTerminals,
} from 'features/Transactions/transactionsSlice';

import {
  GetTransactionsParams,
  TransactionItem,
  TransactionsFilterForm,
  TransactionsQueryParams,
} from '../../types';

import { Transactions } from './TransactionsList.component';

const pageSize = 12;

interface Props {
  page: number;
  queryParams: TransactionsQueryParams;
}

export const TransactionsListContainer: React.FC<Props> = ({ page, queryParams }) => {
  const { cancellablePromise } = useCancellablePromise();
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = useState<TransactionItem[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(true);

  const filterFormData: TransactionsFilterForm = useMemo(() => {
    return cleanObject({
      Search: queryParams.Search,
      StartDate: queryParams.StartDate ? tryParseDate(+queryParams.StartDate) : null,
      EndDate: queryParams.EndDate ? tryParseDate(+queryParams.EndDate) : null,
      MinAmount: queryParams.MinAmount,
      MaxAmount: queryParams.MaxAmount,
      TerminalId: queryParams.TerminalId,
      Status: queryParams.Status ? +queryParams.Status : null,
      PaymentSystem: queryParams.PaymentSystem || null,
    });
  }, [queryParams]);

  useEffect(() => {
    dispatch(fetchFilterStatuses());
    dispatch(fetchFilterTerminals());
    dispatch(fetchFilterPaymentSystems());
  }, []);

  useEffect(() => {
    setLoading(true);

    const filterData: Omit<GetTransactionsParams, 'Page' | 'PageSize'> = cleanObject({
      ...filterFormData,
      MinAmount: filterFormData.MinAmount ? +filterFormData.MinAmount : null,
      MaxAmount: filterFormData.MaxAmount ? +filterFormData.MaxAmount : null,
    });

    if (filterFormData.StartDate) {
      filterData.StartDate = toApiDatetime(
        set(filterFormData.StartDate, {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
      );
    }

    if (filterFormData.EndDate) {
      filterData.EndDate = toApiDatetime(
        set(filterFormData.EndDate, {
          hours: 23,
          minutes: 59,
          seconds: 59,
          milliseconds: 999,
        })
      );
    }

    cancellablePromise(
      getTransactions({
        ...filterData,
        Page: page,
        PageSize: pageSize,
      })
    )
      .then(result => {
        setData(result.data);
        setTotalCount(result.totalCount);
      })
      .catch(
        handleCancellableApiError(error => {
          setError(error.error);
        })
      )
      .finally(() => {
        setLoading(false);
      });
  }, [page, filterFormData]);

  const handleFilterSubmit = useCallback((formData: TransactionsFilterForm) => {
    const final = cleanObject<TransactionsFilterForm>(
      formData,
      cleanObject(formData, { removeEmptyString: true })
    );

    if (final.StartDate) {
      (final.StartDate as any) = final.StartDate.getTime();
    }

    if (final.EndDate) {
      (final.EndDate as any) = final.EndDate.getTime();
    }

    history.push({
      search: toSearchParams(final),
    });
  }, []);

  return (
    <Transactions
      filterFormData={filterFormData}
      queryParams={queryParams}
      data={data}
      pageCount={Math.ceil(totalCount / pageSize)}
      error={error}
      loading={loading}
      onFilterSubmit={handleFilterSubmit}
    />
  );
};
