import React from 'react';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import PaginationItem from '@material-ui/lab/PaginationItem';
import Pagination from '@material-ui/lab/Pagination';
import { colors } from '@abdt/design-tokens';

import { cleanObject, toSearchParams } from 'utils/main';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {},
    page: {
      fontSize: 14,
      color: theme.palette.text.primary,
      borderRadius: 8,
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
      '&:active': {
        backgroundColor: theme.palette.grey[200],
      },
      '&$selected': {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: 'unset',
      },
      '&$selected$disabled': {
        border: `2px solid ${theme.palette.grey[100]}`,
        backgroundColor: 'unset',
      },
      '& [class*="MuiTouchRipple-root"]': {
        display: 'none',
      },
    },
    selected: {},
    disabled: {
      color: colors.gray350,
      opacity: 1,
    },
  })
);

interface Props {
  className?: string;
  rootPath: string;
  count: number;
  queryParams: { page?: string } & Record<any, any>;
}

export const PaginationLink: React.FC<Props> = ({
  className,
  rootPath,
  count,
  queryParams,
}) => {
  const classes = useStyles();

  const copyQuery = { ...queryParams };
  copyQuery.page = copyQuery.page || '1';
  const page = parseInt(copyQuery.page, 10);

  delete copyQuery.page;

  return (
    <Route>
      {() => {
        return (
          <Pagination
            className={className}
            page={page}
            count={count}
            data-testid="pagination"
            renderItem={item => {
              let queryString;

              if (item.page === 1) {
                queryString = toSearchParams(cleanObject(copyQuery));
              } else {
                queryString = toSearchParams(
                  cleanObject({
                    ...copyQuery,
                    page: item.page,
                  })
                );
              }

              if (queryString) {
                queryString = '?' + queryString;
              }

              return (
                <PaginationItem
                  classes={{ ...classes }}
                  component={Link}
                  to={`/${rootPath}${queryString}`}
                  data-testid="pagination-item"
                  {...item}
                />
              );
            }}
          />
        );
      }}
    </Route>
  );
};
