import React from 'react';
import { useLocation } from 'react-router-dom';

import MerchantInfo from 'features/Merchant/components/MerchantInfo';
import { HomeLayout } from 'layouts/Home';
import { decodeSearchParams } from 'utils/main';
import { MerchantInfoQueryParams } from 'features/Merchant/types';

interface Props {}

export const MerchantPage: React.FC<Props> = () => {
  const { search } = useLocation();
  const queryParams = decodeSearchParams<MerchantInfoQueryParams>(search);

  return (
    <HomeLayout>
      <MerchantInfo queryParams={queryParams} />
    </HomeLayout>
  );
};
