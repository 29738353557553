import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Grid,
} from '@abdt/ornament';
import { colors } from '@abdt/design-tokens';
import { format } from 'date-fns';

import { PaginationLink } from 'components/PaginationLink';
import { formatBytes } from 'utils/main';
import { ReportItem, ReportsQueryParams, ReportStatus } from 'features/Reports/types';

import { Status } from '../Status';
import { DownloadReport } from '../DownloadReport';

const useStyles = makeStyles(() => ({
  content: {
    backgroundColor: '#fff',
    borderRadius: 4,
    margin: '20px 0 32px',
  },
  row: {
    textDecoration: 'none',

    '&:hover': {
      backgroundColor: colors.gray50,
    },
  },
  pagination: {
    padding: '16px 12px',
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #e1e1e1',
  },
  secLevel: {
    color: '#6c6c6c',
  },
  textEllipsis: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

interface Props {
  data: ReportItem[];
  queryParams: ReportsQueryParams;
  pageCount: number;
}

export const DataGrid: React.FC<Props> = ({ data, queryParams, pageCount }) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell>Сформирован</TableCell>
              <TableCell>Формат</TableCell>
              <TableCell>Статус</TableCell>
              <TableCell>Размер</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(item => {
              return (
                <TableRow className={classes.row} key={item.id}>
                  <TableCell>
                    <div>{item.reportName}</div>
                  </TableCell>
                  <TableCell>
                    <div>{format(new Date(item.created), 'dd.MM.yyyy')}</div>
                    <div className={classes.secLevel}>
                      {format(new Date(item.created), 'HH:mm')}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div>{item.fileName.split('.')[1].toUpperCase()}</div>
                  </TableCell>
                  <TableCell>
                    <Status status={item.reportStatus} />
                  </TableCell>
                  <TableCell>
                    <Grid
                      container
                      alignItems="center"
                      wrap="nowrap"
                      spacing={2}
                      justify="space-between"
                    >
                      <Grid item>
                        <div>{formatBytes(item.length)}</div>
                      </Grid>
                      <Grid item>
                        {item.reportStatus === ReportStatus.Completed ? (
                          <DownloadReport reportId={item.id} />
                        ) : null}
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className={classes.pagination}>
        <Typography variant="body2">Страница:</Typography>
        <PaginationLink queryParams={queryParams} rootPath="reports" count={pageCount} />
      </div>
    </>
  );
};
