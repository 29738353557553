import React, { useRef } from 'react';
import { TextFieldProps } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import { FormFieldProps } from 'types/form-field';

import { AmountField } from './AmountField.component';

type Props = TextFieldProps & {
  formFieldProps: FormFieldProps;
};

export const AmountFormField: React.FC<Props> = React.memo(props => {
  const {
    formFieldProps: { control, errors, clearErrors, validationRules },
    name,
    ...other
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Controller
      control={control}
      name={name}
      rules={validationRules}
      render={({ onChange, value }) => {
        return (
          <AmountField
            onChange={event => {
              clearErrors(name);
              onChange(event.target.value);
            }}
            error={Boolean(errors[name])}
            value={value}
            inputRef={inputRef}
            name={name}
            {...other}
          />
        );
      }}
      onFocus={() => inputRef.current?.focus()}
    />
  );
});
