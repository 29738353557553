import { toSearchParams, cleanObject } from 'utils/main';

import { getAuthToken } from './token';

export interface RequestOptions {
  baseUrl: string;
  urn: string;
  queryParams?: object;
  body?: object;
  jsonRequest?: boolean;
  public?: boolean;
}

const buildEndpointUrl = (options: RequestOptions): string => {
  let tail = '';

  if (options.queryParams) {
    const objectParams = cleanObject(options.queryParams);

    if (Object.keys(objectParams).length > 0) {
      tail = '?' + toSearchParams(objectParams);
    }
  }

  return `${options.baseUrl}${options.urn}${tail}`;
};

const buildHeaders = (options?: RequestOptions): Headers => {
  const rawHeaders = {};

  let supplyAuthorization = true;

  if (options) {
    if (options.jsonRequest) {
      rawHeaders['Content-Type'] = 'application/json';
    }

    if (options.public) {
      supplyAuthorization = false;
    }
  }

  if (supplyAuthorization) {
    rawHeaders['Authorization'] = `Bearer ${getAuthToken()}`;
  }

  const headers = new Headers();

  Object.keys(rawHeaders).forEach(customHeaderKey => {
    headers.append(customHeaderKey, rawHeaders[customHeaderKey]);
  });

  return headers;
};

const buildBody = (options?: RequestOptions) => {
  if (options.jsonRequest) {
    return JSON.stringify(options.body);
  } else {
    return null;
  }
};

function request(method, options: RequestOptions): Promise<Response> {
  const headers = buildHeaders(options);
  const endpointUrl = buildEndpointUrl(options);
  const body = buildBody(options);

  return fetch(endpointUrl, {
    method,
    body,
    credentials: 'include',
    headers,
  });
}

export function apiPost(options: RequestOptions): Promise<Response> {
  return request('POST', options);
}

export function apiPut(options: RequestOptions): Promise<Response> {
  return request('PUT', options);
}

export function apiPatch(options: RequestOptions): Promise<Response> {
  return request('PATCH', options);
}

export function apiDelete(options: RequestOptions): Promise<Response> {
  return request('DELETE', options);
}

export function apiGet(options: RequestOptions): Promise<Response> {
  return request('GET', options);
}
