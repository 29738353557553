import React, { useCallback, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Search, Close, FilterFunnel } from '@abdt/icons';
import { TextField, IconButton, Grid, Button, Chip } from '@abdt/ornament';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Container } from 'components/Container';
import { DatePickerFormField } from 'components/DatePicker';
import { AmountFormField } from 'components/AmountField';
import { SelectFormField } from 'components/Select';
import { cleanObject } from 'utils/main';
import { RootState } from 'store/root-reducer';

import { ReportContainer } from '../Report'
import { FilterStatus, FilterTerminal, TransactionsFilterForm } from '../../types';

import { getSelectedFilters, SelectedFilter } from './utils';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    backgroundColor: '#f0f0f0',
  },
  search: {
    display: 'flex',
    alignItems: 'center',
  },
  searchField: {
    width: '50%',
    minWidth: 615,
    maxWidth: 700,
    marginRight: 8,
  },
  searchInput: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  searchIcon: {
    marginRight: 8,
  },
  filter: {
    position: 'absolute',
    backgroundColor: '#f0f0f0',
    top: '100%',
    left: 0,
    width: '100%',
  },
  filterWrapper: {
    width: '50%',
    minWidth: 615,
    maxWidth: 700,
    padding: '24px 0',
  },
  filterField: {},
  selectedFilters: {
    marginTop: 20,
  },
  chipRoot: {
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: 4,

    '& + &': {
      marginLeft: 12,
    },
  },
  chipCloseIcon: {
    width: 16,
    height: 16,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

interface Props {
  className?: string;
  defaultValues: TransactionsFilterForm;
  open: boolean;
  onOpen: (open: boolean) => void;
  onSubmit: (formData: TransactionsFilterForm) => void;
}

const initialFilter: TransactionsFilterForm = {
  Status: '',
  StartDate: null,
  EndDate: null,
  MinAmount: null,
  MaxAmount: null,
  TerminalId: '',
};

export const Filter: React.FC<Props> = ({
  className,
  defaultValues,
  open,
  onOpen,
  onSubmit,
}) => {
  const classes = useStyles();
  const {
    register,
    reset,
    handleSubmit,
    errors,
    control,
    getValues,
    clearErrors,
  } = useForm<TransactionsFilterForm>({
    defaultValues: {
      ...initialFilter,
      ...defaultValues,
    },
  });

  const { filterStatuses, filterTerminals, filterPaymentSystems } = useSelector(
    (state: RootState) => state.transactions
  );

  const terminals: FilterTerminal[] = useMemo(() => {
    if (filterTerminals.loaded && !filterTerminals.error) {
      return filterTerminals.data;
    } else {
      return [];
    }
  }, [filterTerminals]);

  const statuses: FilterStatus[] = useMemo(() => {
    if (filterStatuses.loaded && !filterStatuses.error) {
      return filterStatuses.data;
    } else {
      return [];
    }
  }, [filterStatuses]);


  const paymentSystems: string[] = useMemo(() => {
    if (filterPaymentSystems.loaded && !filterPaymentSystems.error) {
      return filterPaymentSystems.data;
    } else {
      return [];
    }
  }, [filterPaymentSystems]);


  const formRef = useRef<HTMLFormElement>();

  const formProps = {
    errors,
    control,
    clearErrors,
  };

  const submit = useCallback(
    formData => {
      onOpen(false);
      onSubmit({
        ...initialFilter,
        ...cleanObject(formData, { removeEmptyString: true }),
      });
    },
    [onSubmit]
  );

  const resetForm = useCallback(() => {
    reset(initialFilter);
    submit(getValues());
  }, []);

  const handleResetItem = useCallback(
    (keys: string[]) => {
      const resetted = {};
      keys.forEach(key => {
        resetted[key] = initialFilter[key];
      });

      return () => {
        const result = {
          ...getValues(),
          ...resetted,
        };

        reset(result);
        submit(result);
      };
    },
    [reset, getValues]
  );

  const selectedFilters: SelectedFilter[] = useMemo(
    () =>
      getSelectedFilters(defaultValues, initialFilter, {
        Status: statuses.find(i => i.number === defaultValues.Status)?.name,
        TerminalId: terminals.find(i => i.id === defaultValues.TerminalId)
          ?.terminalNumber,
      }),
    [defaultValues, initialFilter, statuses, terminals, paymentSystems]
  );

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(submit)}
      className={classNames(classes.root, className)}
      role="search"
      data-testid="filter-form"
    >
      <Container className={classes.actions}>
        <div className={classes.search}>
          <TextField
            InputProps={{
              startAdornment: <Search className={classes.searchIcon} />,
            }}
            className={classes.searchField}
            inputProps={{
              className: classes.searchInput,
            }}
            type="search"
            size="small"
            placeholder="Поиск"
            name="Search"
            inputRef={register()}
          />

          {open ? (
            <IconButton title="Закрыть фильтр" onClick={() => onOpen(false)}>
              <Close />
            </IconButton>
          ) : (
            <IconButton title="Открыть фильтр" onClick={() => onOpen(true)}>
              <FilterFunnel />
            </IconButton>
          )}
        </div>
        <ReportContainer filterFormData={defaultValues}/>
      </Container>

      <Container>
        <div data-testid="selected-filters" className={classes.selectedFilters}>
          {selectedFilters.map((selectedFilter, index) => (
            <Chip
              key={index}
              classes={{
                root: classes.chipRoot,
              }}
              color="primary"
              label={selectedFilter.name}
              variant="outlined"
              deleteIcon={<Close className={classes.chipCloseIcon} />}
              onDelete={handleResetItem(selectedFilter.keys)}
            />
          ))}
        </div>
      </Container>
      <div data-testid="filters" className={classes.filter} hidden={!open}>
        <Container>
          <div className={classes.filterWrapper}>
            <Grid container direction="column" spacing={2}>
              <Grid container item spacing={2}>
                <Grid item xs={4}>
                  <SelectFormField
                    formFieldProps={formProps}
                    label="Статус"
                    native={true}
                    name="Status"
                  >
                    <option aria-label="Не выбрано" value="" />
                    {statuses.map(item => (
                      <option key={item.number} value={item.number}>
                        {item.name}
                      </option>
                    ))}
                  </SelectFormField>
                </Grid>
                <Grid item xs={4}>
                  <DatePickerFormField
                    formFieldProps={{
                      ...formProps,
                      name: 'StartDate',
                    }}
                    label="Начало периода"
                    maxDate={getValues('EndDate')}
                    renderInput={props => (
                      <TextField
                        {...props}
                        name="StartDate"
                        fullWidth={true}
                        size="small"
                        variant="outlined"
                        helperText=""
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DatePickerFormField
                    formFieldProps={{
                      ...formProps,
                      name: 'EndDate',
                    }}
                    label="Конец периода"
                    minDate={getValues('StartDate')}
                    renderInput={props => (
                      <TextField
                        {...props}
                        name="EndDate"
                        fullWidth={true}
                        size="small"
                        variant="outlined"
                        helperText=""
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={4}>
                  <AmountFormField
                    formFieldProps={{
                      ...formProps,
                      validationRules: {
                        max: getValues('MaxAmount') ? getValues('MaxAmount') : Infinity,
                      },
                    }}
                    fullWidth={true}
                    className={classes.filterField}
                    type="text"
                    size="small"
                    label="Минимальная сумма"
                    name="MinAmount"
                  />
                </Grid>
                <Grid item xs={4}>
                  <AmountFormField
                    formFieldProps={{
                      ...formProps,
                      validationRules: {
                        min: getValues('MinAmount') ? getValues('MinAmount') : 0,
                      },
                    }}
                    fullWidth={true}
                    className={classes.filterField}
                    type="text"
                    size="small"
                    label="Максимальная сумма"
                    name="MaxAmount"
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectFormField
                    formFieldProps={formProps}
                    label="Терминал"
                    native={true}
                    name="TerminalId"
                  >
                    <option aria-label="Не выбрано" value="" />
                    {terminals.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.terminalNumber}
                      </option>
                    ))}
                  </SelectFormField>
                </Grid>
                <Grid item xs={4}>
                  <SelectFormField
                    formFieldProps={formProps}
                    label="Платежная система"
                    native={true}
                    name="PaymentSystem"
                  >
                    <option aria-label="Не выбрано" value="" />
                    {paymentSystems.map(item => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </SelectFormField>
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={4}>
                  <Button fullWidth type="submit">
                    Применить фильтр
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button fullWidth variant="outlined" onClick={resetForm}>
                    Сбросить фильтр
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </form>
  );
};
