import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@abdt/ornament';

const useStyles = makeStyles(() => ({
  head: {
    display: 'flex',
    fontSize: 18,
  },
}));

interface Props {
  children: string;
}

export const PageHeadTitle: React.FC<Props> = memo(({ children }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.head} variant="h4">
      {children}
    </Typography>
  );
});
