import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    head: {
      marginBottom: 24,
    },
    field: {
      '& + &': {
        marginTop: 16,
      },
    },
    detailsLogs: {
      width: '70vw',
    },
  })
);
