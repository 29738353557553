import React from 'react';
import { DatePicker } from '@abdt/ornament';
import { DatePickerProps } from '@material-ui/pickers/DatePicker';
import { Controller } from 'react-hook-form';

import { FormFieldProps } from 'types/form-field';

type Props = Omit<DatePickerProps, 'onChange' | 'value'> & {
  formFieldProps: FormFieldProps;
};

export const DatePickerFormField: React.FC<Props> = React.memo(props => {
  const {
    formFieldProps: { control, clearErrors, validationRules, name },
    ...other
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      rules={validationRules}
      render={({ onChange, value }) => {
        return (
          <DatePicker
            onChange={value => {
              clearErrors(name);
              onChange(value);
            }}
            value={value}
            {...other}
          />
        );
      }}
    />
  );
});
