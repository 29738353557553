import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  CircularProgress,
} from '@abdt/ornament';

import { TransactionDetailsLog } from '../../types';

const useStyles = makeStyles(() => ({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  messageTitle: {
    fontSize: 18,
  },
  tableContainer: {
    maxHeight: '60vh',
  },
}));

interface Props {
  className?: string;
  data: TransactionDetailsLog[];
  loading: boolean;
  error: string;
}

export const TransactionDetailsLogs: React.FC<Props> = ({
  className,
  data,
  loading,
  error,
}) => {
  const classes = useStyles();

  const renderContent = useMemo(() => {
    if (loading) {
      return (
        <div className={classes.loader}>
          <CircularProgress size={40} />
        </div>
      );
    } else if (error) {
      return (
        <div className={classes.message}>
          <Typography
            className={classes.messageTitle}
            variant="h4"
            color="error"
            gutterBottom
          >
            Ошибка получения данных
          </Typography>
        </div>
      );
    } else {
      return (
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>DOC__ORIG__ID</TableCell>
                <TableCell>DOC__PREV__ID</TableCell>
                <TableCell>RET_REF_NUMBER</TableCell>
                <TableCell>AMND_PREV</TableCell>
                <TableCell>DOC__SUMM__ID</TableCell>
                <TableCell>AUTH_CODE</TableCell>
                <TableCell>IS_AUTHORIZATION</TableCell>
                <TableCell>POSTING_DATE</TableCell>
                <TableCell>POSTING_STATUS</TableCell>
                <TableCell>RETURN_CODE</TableCell>
                <TableCell>REQUEST_CATEGORY</TableCell>
                <TableCell>TRANS_TYPE</TableCell>
                <TableCell>SOURCE_NUMBER</TableCell>
                <TableCell>TRANS_DATE</TableCell>
                <TableCell>TRANS_AMOUNT</TableCell>
                <TableCell>Offset</TableCell>
                <TableCell>Partition</TableCell>
                <TableCell>OUTWARD_STATUS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(item => {
                return (
                  <TableRow key={item.ID}>
                    <TableCell>{item.ID}</TableCell>
                    <TableCell>{item.DOC__ORIG__ID}</TableCell>
                    <TableCell>{item.DOC__PREV__ID}</TableCell>
                    <TableCell>{item.RET_REF_NUMBER}</TableCell>
                    <TableCell>{item.AMND_PREV}</TableCell>
                    <TableCell>{item.DOC__SUMM__ID}</TableCell>
                    <TableCell>{item.AUTH_CODE}</TableCell>
                    <TableCell>{item.IS_AUTHORIZATION}</TableCell>
                    <TableCell>{item.POSTING_DATE}</TableCell>
                    <TableCell>{item.POSTING_STATUS}</TableCell>
                    <TableCell>{item.RETURN_CODE}</TableCell>
                    <TableCell>{item.REQUEST_CATEGORY}</TableCell>
                    <TableCell>{item.TRANS_TYPE}</TableCell>
                    <TableCell>{item.SOURCE_NUMBER}</TableCell>
                    <TableCell>{item.TRANS_DATE}</TableCell>
                    <TableCell>{item.TRANS_AMOUNT}</TableCell>
                    <TableCell>{item.Offset}</TableCell>
                    <TableCell>{item.Partition}</TableCell>
                    <TableCell>{item.OUTWARD_STATUS}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  }, [loading, data, error]);

  return (
    <div className={className}>
      <Typography variant="h4" gutterBottom>
        Логи транзакции
      </Typography>

      {renderContent}
    </div>
  );
};
