import React, { useCallback, useState } from 'react';
import { IconButton } from '@abdt/ornament';
import { Download } from '@abdt/icons';
import { useSnackbar } from 'notistack';

import {
  handleCancellableApiError,
  useCancellablePromise,
} from 'utils/cancellable-promise';
import { saveAs } from 'utils/main';
import { downloadReportS3 } from 'features/Reports/api';

interface Props {
  reportId: string;
}

export const DownloadReport: React.FC<Props> = ({ reportId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise } = useCancellablePromise();

  const [loading, setLoading] = useState(false);
  const onDownloadReportS3 = useCallback(() => {
    setLoading(true);
    cancellablePromise(downloadReportS3({ reportId }))
      .then(({ blob, fileName: serverFileName }) => {
        let fileName = serverFileName.split('.')[0];
        try {
          fileName = decodeURIComponent(fileName);
        } catch (e) {}
        fileName = `${fileName}.${serverFileName.split('.')[1]}`;
        saveAs(blob, fileName);
      })
      .catch(
        handleCancellableApiError(error => {
          enqueueSnackbar(error.error, {
            variant: 'error',
            autoHideDuration: 3000,
            persist: false,
          });
        })
      )
      .finally(() => {
        setLoading(false);
      });
  }, [reportId]);
  return (
    <IconButton onClick={onDownloadReportS3} disabled={loading}>
      <Download />
    </IconButton>
  );
};
