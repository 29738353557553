import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@abdt/ornament';
import { ChevronRight } from '@abdt/icons';
import { colors } from '@abdt/design-tokens';
import { Link } from 'react-router-dom';

import { MerchantContracts, MerchantInfoQueryParams } from '../../types';

import useStyles from './ContractList.style';

interface Props {
  data: MerchantContracts[];
  queryParams: MerchantInfoQueryParams;
}

const ContractList: React.FC<Props> = ({ data, queryParams }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Номер счета</TableCell>
                <TableCell>Номер контракта</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(item => {
                return (
                  <TableRow
                    className={classes.row}
                    component={Link}
                    hover
                    to={{
                      pathname: `/merchant/contracts/${item.id}`,
                      state: queryParams,
                    }}
                    key={item.id}
                  >
                    <TableCell>
                      <div style={{ maxWidth: '10vw' }}>{item.contractNumber}</div>
                    </TableCell>
                    <TableCell>
                      <Grid
                        container
                        alignItems="center"
                        wrap="nowrap"
                        spacing={0}
                        justify="space-between"
                      >
                        <Grid item>
                          <div>{item.contractName}</div>
                        </Grid>
                        <Grid item>
                          <div className={classes.icon}>
                            <ChevronRight
                              color={colors.nightSky800}
                              style={{ height: '24px', width: '24px' }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default ContractList;
