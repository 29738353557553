import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import { deepmerge } from '@material-ui/utils';
import { themes } from '@abdt/ornament';

const configPalette: Pick<ThemeOptions, 'palette'> = {
  palette: {
    background: {
      default: '#f0f0f0',
    },
  },
};

const configOverrides: Pick<ThemeOptions, 'overrides'> = {
  overrides: {
    MuiTableCell: {
      root: {
        borderBottomColor: '#e1e1e1',
      },
    },
    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: 0,
      },
    },
  },
};

const finalTheme = [configPalette, configOverrides].reduce(
  (acc, curr) => deepmerge(acc, curr),
  themes.base
);

export const theme = createMuiTheme(finalTheme);
