import React from 'react';
import { useLocation } from 'react-router-dom';

import { TransactionsListContainer } from 'features/Transactions/components/TransactionsList';
import { HomeLayout } from 'layouts/Home';
import { decodeSearchParams } from 'utils/main';
import { TransactionsQueryParams } from 'features/Transactions/types';

interface Props {}

export const TransactionsPage: React.FC<Props> = () => {
  const { search } = useLocation();
  const queryParams = decodeSearchParams<TransactionsQueryParams>(search);
  const { page } = queryParams;

  return (
    <HomeLayout>
      <TransactionsListContainer page={page ? +page : 1} queryParams={queryParams} />
    </HomeLayout>
  );
};
