import React, { useState, useEffect, useRef } from 'react';

export const useRemainTimer = (countFromSec: number, lastDate: number) => {
  const [remainTimeSec, setRemainTimeSec] = useState(countFromSec);
  const remainTimer = useRef(null);

  useEffect(() => {
    setRemainTimeSec(countFromSec);

    const timer = () => {
      remainTimer.current = setTimeout(() => {
        if (!remainTimer.current) {
          return;
        }

        setRemainTimeSec(prev => prev - 1);

        timer();
      }, 1000);
    };

    timer();

    return () => {
      clearTimeout(remainTimer.current);
      remainTimer.current = null;
    };
  }, [lastDate]);

  useEffect(() => {
    if (remainTimeSec <= 0) {
      clearTimeout(remainTimer.current);
      remainTimer.current = null;
    }
  }, [remainTimeSec]);

  return remainTimeSec;
};
