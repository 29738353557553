import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { toSearchParams } from 'utils/main';
import { RootState } from 'store/root-reducer';
import { MerchantInfoQueryParams } from 'features/Merchant/types';

import { fetchMerchantDetails } from '../../merchantSlice';

import MerchantInfo from './MerchantInfo.component';

interface Props {
  queryParams: MerchantInfoQueryParams;
}

const MerchantInfoContainer: React.FC<Props> = ({ queryParams }) => {
  const location = useLocation<any>();

  const { merchantDetails } = useSelector((state: RootState) => state.merchant);
  const { data } = useSelector((state: RootState) => state.profile);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMerchantDetails(data.merchantId));
  }, []);

  let returnQueryParams = '';

  if (location.state) {
    returnQueryParams = `?${toSearchParams(location.state)}`;
  }

  return (
    <MerchantInfo
      returnQueryParams={returnQueryParams}
      data={merchantDetails.data}
      error={merchantDetails.error}
      loading={merchantDetails.loading}
      queryParams={queryParams}
    />
  );
};

export default MerchantInfoContainer;
