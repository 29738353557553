import React from 'react';
import { Button, CircularProgress } from '@abdt/ornament';

interface Props {
  onReportDownload: VoidFunction;
  reportLoading: boolean;
}

export const Report: React.FC<Props> = ({ onReportDownload, reportLoading }) => {
  return (
    <div>
      <Button
        disabled={reportLoading}
        startIcon={reportLoading && <CircularProgress size={20} />}
        onClick={onReportDownload}
        variant="contained"
      >
        Скачать данные
      </Button>
    </div>
  );
};
