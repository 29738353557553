import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    container: {
      height: '100%',
    },
    tabs: {
      borderBottom: 'none',
    },
    tabsContainer: {
      backgroundColor: '#fff'
    },
    content: {
      backgroundColor: '#fff',
      borderRadius: 4,
      margin: '32px 0',
      padding: '24px',
    },
    loader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    message: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    messageTitle: {
      fontSize: 18,
    },
    backLink: {
      letterSpacing: 'inherit',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      padding: 0,
      fontWeight: 'inherit',

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  })
);
