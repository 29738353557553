import React from 'react';
import { TextFieldProps } from '@material-ui/core';
import { TextField } from '@abdt/ornament';

import { NumberFormatCustom } from 'components/NumberFormatCustom';

export const AmountField: React.FC<TextFieldProps> = props => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
      }}
    />
  );
};
