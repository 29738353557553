import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/root-reducer';
import { MerchantTerminal, MerchantContracts } from 'features/Merchant/types';

import { fetchMerchantDetails } from '../../merchantSlice';

import TerminalInfo from './TerminalInfo.component';

interface Props {
  contractId: string;
  terminalId: string;
}

const TerminalInfoContainer: React.FC<Props> = ({ contractId, terminalId }) => {
  const { merchantDetails } = useSelector((state: RootState) => state.merchant);
  const { data } = useSelector((state: RootState) => state.profile);

  const [terminal, setterminal] = useState<MerchantTerminal>();
  const [contract, setcontract] = useState<MerchantContracts>();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMerchantDetails(data.merchantId));
  }, []);

  useEffect(() => {
    if (merchantDetails.data && contractId) {
      setcontract(
        merchantDetails.data.contracts.find(_contract => _contract.id === contractId)
      );

      setterminal(contract?.terminals.find(_terminal => _terminal.id === terminalId));
    }
  }, [merchantDetails, contractId, contract]);

  return (
    <TerminalInfo
      data={terminal}
      error={merchantDetails.error}
      loading={merchantDetails.loading}
      merchantContract={contract}
    />
  );
};

export default TerminalInfoContainer;
