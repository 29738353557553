import { colors } from '@abdt/design-tokens';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    row: {
      textDecoration: 'none',

      '&:hover': {
        backgroundColor: colors.gray50,
      },
    },
    pagination: {
      padding: '16px 12px',
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid #e1e1e1',
    },
    secLevel: {
      color: '#6c6c6c',
    },
    textEllipsis: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    container: {
      height: '100%',
    },
    tabs: {
      borderBottom: 'none',
      backgroundColor: '#fff',
      padding: '0 16px',

      [theme.breakpoints.up('lg')]: {
        padding: '0 32px',
      },
    },
    content: {
      backgroundColor: '#fff',
      borderRadius: 4,
      margin: '32px 0',
      padding: '24px',
    },
    loader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    message: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    messageTitle: {
      fontSize: 18,
    },
    backLink: {
      letterSpacing: 'inherit',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      padding: 0,
      fontWeight: 'inherit',

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  })
);
