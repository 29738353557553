import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Tooltip,
} from '@abdt/ornament';
import { ArrowLeft } from '@abdt/icons';
import { Link } from 'react-router-dom';

import { PageHead } from 'components/PageHead';
import { Container } from 'components/Container';
import { PageHeadTitle } from 'components/PageHeadTitle';

import { ChargebackContainer } from '../Chargeback';
import { TransactionDetailsInfo } from '../TransactionDetailsInfo';
import { TransactionDocs } from '../TransactionDocs';
import { Transaction } from '../../types';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  container: {
    height: '100%',
  },
  content: {
    backgroundColor: '#fff',
    borderRadius: 4,
    margin: '20px 0 32px',
    padding: '32px 24px',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  messageTitle: {
    fontSize: 18,
  },
  backLink: {
    letterSpacing: 'inherit',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    padding: 0,
    fontWeight: 'inherit',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  chargebackRoot: {
    width: 432,
  },
}));

interface Props {
  data: Transaction;
  error: string;
  loading: boolean;
  returnQueryParams: string;
}

export const TransactionDetails: React.FC<Props> = ({
  data,
  error,
  loading,
  returnQueryParams,
}) => {
  const classes = useStyles();

  const [openChargeback, setOpenChargeback] = useState(false);

  const renderContent = useMemo(() => {
    if (loading) {
      return (
        <div className={classes.loader}>
          <CircularProgress size={40} />
        </div>
      );
    } else if (error) {
      return (
        <div className={classes.message}>
          <Typography
            className={classes.messageTitle}
            variant="h4"
            color="error"
            gutterBottom
          >
            Ошибка получения данных
          </Typography>
        </div>
      );
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Paper className={classes.content} elevation={0}>
              <TransactionDetailsInfo data={data} />
            </Paper>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Paper className={classes.content} elevation={0}>
              <TransactionDocs docs={data.transactionDocs} />
            </Paper>
          </Grid>
        </Grid>
      );
    }
  }, [loading, data, error]);

  return (
    <>
      <section className={classes.root}>
        <PageHead verticalSpacing={1}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Button
                className={classes.backLink}
                component={Link}
                to={`/transactions${returnQueryParams}`}
                variant="text"
                color="inherit"
                startIcon={<ArrowLeft />}
              >
                <PageHeadTitle>Детали транзакции</PageHeadTitle>
              </Button>
            </Grid>
            <Grid item>
              <Tooltip title={data?.refund?.canRefund ? '' : data?.refund?.description}>
                <span style={{ display: 'flex' }}>
                  <Button
                    disabled={!data?.refund?.canRefund || loading}
                    onClick={() => setOpenChargeback(true)}
                  >
                    {loading ? <CircularProgress size={20} /> : data?.refund?.nameButton}
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </PageHead>
        <Container className={classes.container}>{renderContent}</Container>
      </section>
      <Modal
        open={openChargeback}
        onClose={() => setOpenChargeback(false)}
        transitionTimeout={100}
      >
        {openChargeback && (
          <ChargebackContainer
            className={classes.chargebackRoot}
            onCancel={() => setOpenChargeback(false)}
            transactionDetail={data}
          />
        )}
      </Modal>
    </>
  );
};
