import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 400,
  },
  button: {
    marginRight: theme.spacing(4),
  },
}));
